import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  decreaseUnreadMessagesCount,
  destroyApplication,
  goOffline,
  goOnline,
  goOnlineSucceeded,
  increaseUnreadMessagesCount,
  initApplication,
  setPreviousUrl,
  setIsMobileApp,
  setUnreadMessagesCount
} from './status.actions';
import { Observable } from 'rxjs';
import {
  selectOnline,
  selectPreviousUrl,
  selectIsMobileApp,
  selectUnreadMessagesCount
} from './status.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatusFacade {
  unreadMessagesCount$: Observable<number> = this.store.pipe(
    select(selectUnreadMessagesCount)
  );
  online$: Observable<boolean> = this.store.pipe(select(selectOnline));
  connectionHasBeenRestored$: Observable<boolean> = this.actions$.pipe(
    ofType(goOnlineSucceeded),
    mapTo(true)
  );
  previousUrl$: Observable<string> = this.store.pipe(select(selectPreviousUrl));
  isMobileApp$: Observable<boolean> = this.store.pipe(
    select(selectIsMobileApp)
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  initApplication(): void {
    this.store.dispatch(initApplication());
  }

  destroyApplication(): void {
    this.store.dispatch(destroyApplication());
  }

  setUnreadMessagesCount(unreadMessagesCount: number): void {
    this.store.dispatch(setUnreadMessagesCount({ unreadMessagesCount }));
  }

  increaseUnreadMessagesCount(): void {
    this.store.dispatch(increaseUnreadMessagesCount());
  }

  decreaseUnreadMessagesCount(): void {
    this.store.dispatch(decreaseUnreadMessagesCount());
  }

  setPreviousUrl(url: string): void {
    this.store.dispatch(setPreviousUrl({ url }));
  }

  goOffline(): void {
    this.store.dispatch(goOffline());
  }

  goOnline(): void {
    this.store.dispatch(goOnline());
  }

  setIsMobileApp(isMobileApp: boolean): void {
    this.store.dispatch(setIsMobileApp({ isMobileApp }));
  }
}
