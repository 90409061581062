import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './member.reducer';
import { getMembers, setOnlineStatus } from './member.actions';
import { Observable } from 'rxjs';
import { Member } from './member.types';
import {
  selectAll,
  selectError,
  selectLoaded,
  selectLoading
} from './member.selectors';
import { filter, map } from 'rxjs/operators';
import { ErrorState } from '../../../common/call-state';

@Injectable({
  providedIn: 'root'
})
export class MemberFacade {
  members$: Observable<Member[]> = this.store.pipe(
    select(selectAll),
    map((members: Member[]) =>
      members.sort((a: Member, b: Member) => a.name.localeCompare(b.name))
    )
  );
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));
  error$: Observable<ErrorState> = this.store.pipe(
    select(selectError),
    filter((error): error is ErrorState => !!error)
  );

  constructor(private readonly store: Store<State>) {}

  getMembers(): void {
    this.store.dispatch(getMembers());
  }

  setOnlineStatus(memberId: number, isOnline: boolean): void {
    this.store.dispatch(setOnlineStatus({ memberId, isOnline }));
  }
}
