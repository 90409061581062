import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { StreamChatFacade } from '../stream-chat/+state/stream-chat/stream-chat.facade';
import { ErrorPageRedirectReason } from '../app.types';

@Injectable({
  providedIn: 'root'
})
export class StreamChatGuard  {
  constructor(
    private readonly streamChatFacade: StreamChatFacade,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.streamChatFacade.initializeStreamChat();

    return combineLatest([
      this.streamChatFacade.loaded$.pipe(startWith(false)),
      this.streamChatFacade.error$.pipe(startWith(false))
    ]).pipe(
      filter(([loaded, error]) => !!loaded || !!error),
      take(1),
      map(([loaded]) => {
        if (loaded) {
          return true;
        } else {
          this.router.navigate(['error'], {
            state: { reason: ErrorPageRedirectReason.INIT_STREAM_CHAT_ERROR }
          });
          return false;
        }
      })
    );
  }
}
