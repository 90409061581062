import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { combineReducers, StoreModule } from '@ngrx/store';
import * as fromMember from './member/member.reducer';
import * as fromCryptoKey from './crypto-key/crypto-key.reducer';
import { FEATURE_KEY } from './team-chat.state';
import { EffectsModule } from '@ngrx/effects';
import { CryptoKeyEffects } from './crypto-key/crypto-key.effects';
import { MemberEffects } from './member/member.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      FEATURE_KEY,
      combineReducers({
        [fromMember.FEATURE_KEY]: fromMember.reducer,
        [fromCryptoKey.FEATURE_KEY]: fromCryptoKey.reducer
      })
    ),
    EffectsModule.forFeature([CryptoKeyEffects, MemberEffects])
  ]
})
export class TeamChatStateModule {}
