import { Action, createReducer, on } from '@ngrx/store';
import {
  decreaseUnreadMessagesCount,
  goOfflineSucceeded,
  goOnlineSucceeded,
  increaseUnreadMessagesCount,
  setIsMobileAppSucceed,
  setPreviousUrlSucceeded,
  setUnreadMessagesCount
} from './status.actions';

export const FEATURE_KEY = 'status';

export interface State {
  unreadMessagesCount: number;
  online: boolean;
  previousUrl: string;
  isMobileApp: boolean;
}

export const initialState: State = {
  unreadMessagesCount: 0,
  previousUrl: null,
  online: false,
  isMobileApp: false
};

const statusReducer = createReducer(
  initialState,
  on(setUnreadMessagesCount, (state, { unreadMessagesCount }) => ({
    ...state,
    unreadMessagesCount
  })),
  on(increaseUnreadMessagesCount, (state) => ({
    ...state,
    unreadMessagesCount: state.unreadMessagesCount + 1
  })),
  on(decreaseUnreadMessagesCount, (state) => ({
    ...state,
    unreadMessagesCount: state.unreadMessagesCount - 1 ?? 0
  })),
  on(setPreviousUrlSucceeded, (state, { url }) => ({
    ...state,
    previousUrl: url
  })),
  on(setIsMobileAppSucceed, (state, { isMobileApp }) => ({
    ...state,
    isMobileApp
  })),
  on(goOfflineSucceeded, (state) => ({
    ...state,
    online: false
  })),
  on(goOnlineSucceeded, (state) => ({
    ...state,
    online: true
  }))
);

export const reducer = (state: State, action: Action): State => {
  return statusReducer(state, action);
};
