import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadPractice,
  loadPracticeFailed,
  loadPracticeSucceeded
} from './practice.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Practice, PracticeService } from '../../data-access/practice';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PracticeEffects {
  loadPractice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPractice),
      switchMap(() =>
        this.practiceService.getPracticeData().pipe(
          map((practice: Practice) => loadPracticeSucceeded({ practice })),
          catchError((error) => of(loadPracticeFailed(error)))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly practiceService: PracticeService
  ) {}
}
