import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY, reducer } from './authentication-token.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(FEATURE_KEY, reducer)]
})
export class AuthenticationTokenStateModule {}
