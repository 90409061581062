import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './stream-chat.reducer';
import { Observable } from 'rxjs';
import {
  selectError,
  selectLoaded,
  selectLoading
} from './stream-chat.selectors';
import { ErrorState } from '../../../common/call-state';
import { MutedChannel } from '../../stream-chat.types';
import {
  initializeStreamChat,
  unmuteChannel,
  updateMuteStatus
} from './stream-chat.actions';

@Injectable({
  providedIn: 'root'
})
export class StreamChatFacade {
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));
  error$: Observable<ErrorState> = this.store.pipe(select(selectError));

  constructor(private readonly store: Store<State>) {}

  initializeStreamChat(): void {
    this.store.dispatch(initializeStreamChat());
  }

  updateMuteStatus(mutedChannels: MutedChannel[]): void {
    this.store.dispatch(updateMuteStatus({ mutedChannels }));
  }

  unmuteChannel(channelId: string): void {
    this.store.dispatch(unmuteChannel({ channelId }));
  }
}
