import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { PracticeFacade } from '../../+state/practice/practice.facade';
import { APP_CONFIGURATION, AppConfiguration } from '../../app.types';

@Injectable()
export class PracticeGuidInterceptor implements HttpInterceptor {
  constructor(
    private readonly practiceFacade: PracticeFacade,
    @Inject(APP_CONFIGURATION) private readonly config: AppConfiguration
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.practiceFacade.practiceGuid$.pipe(
      take(1),
      concatMap((practiceGuid) => {
        return next.handle(
          request.clone({
            url: request.url.replace(
              this.config.apiUrl,
              `${this.config.apiUrl}/${practiceGuid}`
            )
          })
        );
      })
    );
  }
}
