import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthenticationTokenFacade } from '../+state/authentication-token/authentication-token.facade';
import { UserFacade } from '../+state/user/user.facade';
import { filter, map, startWith, take } from 'rxjs/operators';
import { ErrorPageRedirectReason } from '../app.types';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenGuard  {
  constructor(
    private readonly router: Router,
    private readonly authenticationTokenFacade: AuthenticationTokenFacade,
    private readonly userFacade: UserFacade
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const authenticationToken = route.queryParams?.token;

    if (!authenticationToken) {
      this.router.navigate(['error'], {
        state: { reason: ErrorPageRedirectReason.NO_AUTH_TOKEN }
      });
      return of(false);
    }

    this.authenticationTokenFacade.setAuthenticationToken(authenticationToken);
    this.userFacade.loadUser();

    return combineLatest([
      this.userFacade.loaded$.pipe(startWith(false)),
      this.userFacade.error$.pipe(startWith(false))
    ]).pipe(
      filter(([loaded, error]) => !!loaded || !!error),
      take(1),
      map(([loaded]) => {
        if (loaded) {
          return true;
        } else {
          this.router.navigate(['error'], {
            state: { reason: ErrorPageRedirectReason.LOAD_USER_ERROR }
          });
          return false;
        }
      })
    );
  }
}
