import { entityAdapter, FEATURE_KEY, State } from './member.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from '../../../common/call-state';
import * as fromTeamChat from '../team-chat.state';

const teamChatSelectState = createFeatureSelector<fromTeamChat.TeamChatState>(
  fromTeamChat.FEATURE_KEY
);
const selectState = createSelector(
  teamChatSelectState,
  (state) => state[FEATURE_KEY]
);

const adapterSelectors = entityAdapter.getSelectors();

export const selectAll = createSelector(
  selectState,
  adapterSelectors.selectAll
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADING
);

export const selectLoaded = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADED
);

export const selectError = createSelector(selectState, (state: State) => {
  return typeof state.callState !== 'object' ? null : state.callState;
});
