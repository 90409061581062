import { Inject, Injectable } from '@angular/core';
import { APP_CONFIGURATION, AppConfiguration } from '../../app.types';
import {
  CommunicationEvent,
  CommunicationEventData
} from '@localmed/modento-team-chat-types';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  constructor(
    @Inject(APP_CONFIGURATION)
    private readonly appConfiguration: AppConfiguration
  ) {}

  sendEvent(event: CommunicationEvent<CommunicationEventData>): void {
    parent.postMessage(event, '*');
  }

  checkIfPostMessageOriginIsValid(origin: string): boolean {
    return (
      this.checkIfPostMessageComesFromDashboard(origin) ||
      this.checkIfPostMessageComesFromModentoGo(origin)
    );
  }

  checkIfPostMessageComesFromModentoGo(origin: string): boolean {
    return this.appConfiguration.postMessage.allowedModentoGoUrls.includes(
      origin
    );
  }

  checkIfPostMessageComesFromDashboard(origin: string): boolean {
    return this.appConfiguration.postMessage.allowedDashboardUrls.includes(
      origin
    );
  }
}
