import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { PracticeFacade } from '../+state/practice/practice.facade';
import { ErrorPageRedirectReason } from '../app.types';

@Injectable({
  providedIn: 'root'
})
export class PracticeGuard  {
  constructor(
    private readonly practiceFacade: PracticeFacade,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const practiceGuid: string = route.params.practiceGuid;

    if (!practiceGuid) {
      this.router.navigate(['error'], {
        state: { reason: ErrorPageRedirectReason.NO_PRACTICE_GUID }
      });
      return of(false);
    }

    this.practiceFacade.loadPractice(practiceGuid);

    return combineLatest([
      this.practiceFacade.loaded$.pipe(startWith(false)),
      this.practiceFacade.error$.pipe(startWith(false))
    ]).pipe(
      filter(([loaded, error]) => !!loaded || !!error),
      take(1),
      map(([loaded]) => {
        if (loaded) {
          return true;
        } else {
          this.router.navigate(['error'], {
            state: { reason: ErrorPageRedirectReason.LOAD_PRACTICE_ERROR }
          });
          return false;
        }
      })
    );
  }
}
