import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(name: string, searchPhrase: string): string {
    if (!searchPhrase) {
      return name;
    }
    const startIndex: number = name
      .toLocaleLowerCase()
      .indexOf(searchPhrase.toLocaleLowerCase());

    if (startIndex === -1) {
      return name;
    }

    return (
      name.substr(0, startIndex) +
      '<span class="highlighted">' +
      name.substr(startIndex, searchPhrase.length) +
      '</span>' +
      name.substr(startIndex + searchPhrase.length)
    );
  }
}
