import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  archiveChannel,
  deleteChannel,
  deleteChannelSucceeded,
  designateModeratorAndLeaveChannel,
  hideChannel,
  leaveChannel,
  markChannelAsRead,
  muteChannel,
  showChannel,
  unmuteChannel
} from './channel-action.actions';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { selectLoading } from './channel-action.selectors';
import { ChannelTypes } from '../../../stream-chat/stream-chat.types';

@Injectable({
  providedIn: 'root'
})
export class ChannelActionFacade {
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));

  channelHasBeenDeleted$: Observable<boolean> = this.actions$.pipe(
    ofType(deleteChannelSucceeded),
    map(() => true)
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  muteChannel(channelId: string, duration: number): void {
    this.store.dispatch(muteChannel({ channelId, duration }));
  }

  unmuteChannel(channelId: string): void {
    this.store.dispatch(unmuteChannel({ channelId }));
  }

  markChannelAsRead(channelId: string): void {
    this.store.dispatch(markChannelAsRead({ channelId }));
  }

  showChannel(channelId: string): void {
    this.store.dispatch(showChannel({ channelId }));
  }

  hideChannel(channelId: string): void {
    this.store.dispatch(hideChannel({ channelId }));
  }

  deleteChannel(channelId: string): void {
    this.store.dispatch(deleteChannel({ channelId }));
  }

  leaveChannel(channelId: string): void {
    this.store.dispatch(leaveChannel({ channelId }));
  }

  archiveChannel(channelId: string, channelType: ChannelTypes): void {
    this.store.dispatch(archiveChannel({ channelId, channelType }));
  }

  designateModeratorAndLeaveChannel(
    channelId: string,
    channelType: ChannelTypes,
    newModeratorMemberIds: number[]
  ): void {
    this.store.dispatch(
      designateModeratorAndLeaveChannel({
        channelId,
        channelType,
        newModeratorMemberIds
      })
    );
  }
}
