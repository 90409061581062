import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './user.reducer';
import { loadUser } from './user.actions';
import { Observable } from 'rxjs';
import { User } from '../../data-access/user';
import {
  selectError,
  selectLoaded,
  selectLoading,
  selectUser
} from './user.selectors';
import { filter } from 'rxjs/operators';
import { ErrorState } from '../../common/call-state';

@Injectable({
  providedIn: 'root'
})
export class UserFacade {
  user$: Observable<User> = this.store.pipe(
    select(selectUser),
    filter((user): user is User => !!user)
  );
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));
  error$: Observable<ErrorState> = this.store.pipe(
    select(selectError),
    filter((error): error is ErrorState => !!error)
  );

  constructor(private readonly store: Store<State>) {}

  loadUser(): void {
    this.store.dispatch(loadUser());
  }
}
