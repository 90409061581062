import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageRedirectReason } from '../../app.types';

@Injectable({
  providedIn: 'any'
})
export class NavigationService {
  private readonly basePath: string;
  private readonly token: string;

  constructor(private readonly router: Router) {
    const segments = this.router.parseUrl(this.router.url).root.children.primary
      .segments;
    const queryParams = this.router.parseUrl(this.router.url).queryParams;

    if (segments.length < 1 || !queryParams.token) {
      this.router.navigate(['error'], {
        state: { reason: ErrorPageRedirectReason.INCORRECT_ROUTER_STATE }
      });
    }

    this.token = queryParams.token;

    this.basePath = `/${segments[0]}`;

    if (!!segments.find((segment) => segment.toString() === 'panel')) {
      this.basePath += '/panel';
    }
  }

  goToChannels(): Promise<boolean> {
    return this.router.navigate([`${this.basePath}/channels`], {
      queryParams: {
        token: this.token
      }
    });
  }

  goToChannel(
    channelId: string,
    isNewGroup: boolean = false
  ): Promise<boolean> {
    return this.router.navigate([`${this.basePath}/channel/${channelId}`], {
      state: {
        isNewGroup
      },
      queryParams: {
        token: this.token
      }
    });
  }

  goToGroupChannelDetails(channelId: string): Promise<boolean> {
    return this.router.navigate(
      [`${this.basePath}/group-channel-details/${channelId}`],
      {
        queryParams: {
          token: this.token
        }
      }
    );
  }

  goToAddMember(channelId: string): Promise<boolean> {
    return this.router.navigate([`${this.basePath}/add-member/${channelId}`], {
      queryParams: {
        token: this.token
      }
    });
  }

  goToNewChannel(): Promise<boolean> {
    return this.router.navigate([`${this.basePath}/new-channel`], {
      queryParams: {
        token: this.token
      }
    });
  }

  goToNewGroupChannel(): Promise<boolean> {
    return this.router.navigate([`${this.basePath}/new-group-channel`], {
      queryParams: {
        token: this.token
      }
    });
  }
}
