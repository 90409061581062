import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationTokenGuard } from './guards/authentication-token.guard';
import { PracticeGuard } from './guards/practice.guard';
import { CryptoKeyGuard } from './guards/crypto-key.guard';
import { StreamChatGuard } from './guards/stream-chat.guard';
import { MemberGuard } from './guards/member.guard';
import { ErrorViewComponent } from './error/error-view.component';
import { ChannelsGuard } from './guards/channels.guard';
import { TeamChatComponent } from './team-chat/team-chat.component';

const routes: Routes = [
  {
    path: 'error',
    component: ErrorViewComponent
  },
  {
    path: ':practiceGuid',
    component: TeamChatComponent,
    canActivate: [
      PracticeGuard,
      AuthenticationTokenGuard,
      CryptoKeyGuard,
      StreamChatGuard,
      MemberGuard,
      ChannelsGuard
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'channels'
      },
      {
        path: 'channels',
        loadChildren: () =>
          import('./channels/channel-list.module').then(
            (m) => m.ChannelListModule
          )
      },
      {
        path: 'channel',
        loadChildren: () =>
          import('./channel/channel.module').then((m) => m.ChannelModule)
      },
      {
        path: 'new-channel',
        loadChildren: () =>
          import('./new-channel/new-channel.module').then(
            (m) => m.NewChannelModule
          )
      },
      {
        path: 'new-group-channel',
        loadChildren: () =>
          import('./new-group-channel/new-group-channel.module').then(
            (m) => m.NewGroupChannelModule
          )
      },
      {
        path: 'group-channel-details',
        loadChildren: () =>
          import('./group-channel-details/group-channel-details.module').then(
            (m) => m.GroupChannelDetailsModule
          )
      },
      {
        path: 'add-member',
        loadChildren: () =>
          import('./add-member/add-member-view.module').then(
            (m) => m.AddMemberViewModule
          )
      },
      {
        path: 'panel',
        loadChildren: () =>
          import('./panel/panel.module').then((m) => m.PanelModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'error'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
