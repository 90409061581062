import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor() {}

  encrypt(message: string, key: string): string {
    return AES.encrypt(message, key).toString();
  }

  decrypt(input: string, key: string): string {
    try {
      const bytes = AES.decrypt(input, key);
      return bytes.toString(enc.Utf8);
    } catch (e) {
      return '(!!! failed decrypting message !!!)';
    }
  }
}
