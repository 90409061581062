import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getChannels,
  getChannelsFailed,
  getChannelsSucceeded
} from '../../../+state/channels/channels.actions';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import * as moment from 'moment';
import { ConverterService } from '../../converter.service';
import { StatusFacade } from '../../../+state/status/status.facade';
import { CommunicationService } from '../../../services/communication/communication.service';
import { StreamChatService } from '../../stream-chat.service';
import { UserFacade } from '../../../+state/user/user.facade';
import { CommunicationEventType } from '@localmed/modento-team-chat-types';

@Injectable()
export class StreamChatChannelsEffects {
  getChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChannels),
      withLatestFrom(this.userFacade.user$),
      switchMap(([action, user]) =>
        combineLatest([
          this.streamChatService.fetchChannels({
            members: { $in: [user.team_member.id.toString()] }
          }),
          this.streamChatService.fetchChannels({
            members: { $in: [user.team_member.id.toString()] },
            hidden: true
          })
        ]).pipe(
          tap(([visibleStreamChatChannels, hiddenStreamChatChannels]) => {
            const streamChatChannels = [
              ...visibleStreamChatChannels,
              ...hiddenStreamChatChannels
            ];

            let lastMessageDate: moment.Moment = null;

            streamChatChannels.forEach((streamChatChannel) => {
              this.streamChatService.addChannel(streamChatChannel);

              if (!streamChatChannel.lastMessage()) {
                return;
              }

              const channelLastMessageDate = moment(
                streamChatChannel.lastMessage().created_at
              );

              if (
                !lastMessageDate ||
                channelLastMessageDate.isAfter(lastMessageDate)
              ) {
                lastMessageDate = channelLastMessageDate;
              }
            });

            this.communicationService.sendEvent({
              type: CommunicationEventType.CHANNELS_FETCHED,
              data: {
                lastMessageAt: lastMessageDate?.toISOString() ?? null
              }
            });
          }),
          map(([visibleStreamChatChannels, hiddenStreamChatChannels]) =>
            [
              ...visibleStreamChatChannels.map((streamChatChannel) =>
                this.converterService.convertStreamChatChannelToChannel(
                  streamChatChannel
                )
              ),
              ...hiddenStreamChatChannels.map((streamChatChannel) =>
                this.converterService.convertStreamChatChannelToChannel(
                  streamChatChannel,
                  true
                )
              )
            ].filter((channel) => !!channel)
          ),
          map((channels) => getChannelsSucceeded({ channels })),
          catchError((error) => of(getChannelsFailed(error)))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly streamChatService: StreamChatService,
    private readonly userFacade: UserFacade,
    private readonly converterService: ConverterService,
    private readonly statusFacade: StatusFacade,
    private readonly communicationService: CommunicationService
  ) {}
}
