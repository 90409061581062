import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from '../../../common/call-state';
import { FEATURE_KEY, State } from './channel-action.reducer';

const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADING
);

export const selectLoaded = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADED
);
