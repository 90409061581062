import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './authentication-token.reducer';
import { setAuthenticationToken } from './authentication-token.actions';
import { Observable } from 'rxjs';
import { selectAuthenticationToken } from './authentication-token.selectors';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenFacade {
  authenticationToken$: Observable<string> = this.store.pipe(
    select(selectAuthenticationToken),
    filter(
      (authenticationToken): authenticationToken is string =>
        !!authenticationToken
    )
  );

  constructor(private readonly store: Store<State>) {}

  setAuthenticationToken(authenticationToken: string): void {
    this.store.dispatch(setAuthenticationToken({ authenticationToken }));
  }
}
