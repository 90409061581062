import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './crypto-key.reducer';
import { getCryptoKey } from './crypto-key.actions';
import { Observable } from 'rxjs';
import {
  selectCryptoKey,
  selectError,
  selectLoaded,
  selectLoading
} from './crypto-key.selectors';
import { ErrorState } from '../../../common/call-state';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CryptoKeyFacade {
  cryptoKey$: Observable<string> = this.store.pipe(select(selectCryptoKey));

  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));
  error$: Observable<ErrorState> = this.store.pipe(
    select(selectError),
    filter((error): error is ErrorState => !!error)
  );

  constructor(private readonly store: Store<State>) {}

  getCryptoKey(): void {
    this.store.dispatch(getCryptoKey());
  }
}
