<div
  class="vh-100 d-flex flex-column justify-content-center align-items-center"
>
  <span class="font-weight-bold mb-2">{{
    'error.oh-no.title' | translate
  }}</span>
  <span class="description text-center mx-5 mb-4">
    {{ 'error.oh-no.something-went-wrong' | translate }}<br />
    {{ 'error.oh-no.be-patient' | translate }}
    <div class="mt-2" *ngIf="(user$ | async)?.role === 'admin'">
      {{ 'error.oh-no.admin-info' | translate }}
    </div>
  </span>
  <button mat-flat-button color="primary" (click)="refresh()">
    {{ 'error.oh-no.button.refresh' | translate }}
  </button>
</div>
