import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TeamMember } from '../data-access/user/user.model';
import {
  Channel,
  ChannelAPIResponse,
  ChannelOptions,
  ConnectionOpen,
  PartialUpdateChannel,
  StreamChat,
  UpdateChannelAPIResponse
} from 'stream-chat';
import {
  APIResponse,
  ChannelFilters,
  ChannelSort,
  DeleteChannelAPIResponse
} from 'stream-chat/src/types';
import { from, Observable, of } from 'rxjs';
import { ChannelsFacade } from '../+state/channels/channels.facade';
import { StreamChatFacade } from './+state/stream-chat/stream-chat.facade';
import * as moment from 'moment';
import { ChannelFacade } from '../+state/channel/channel.facade';
import {
  ChannelTypes,
  MutedChannel,
  QueryUsersResponse,
  StreamChatChannel
} from './stream-chat.types';
import { APP_CONFIGURATION, AppConfiguration } from '../app.types';
import { v4 as uuidv4 } from 'uuid';
import { StatusFacade } from '../+state/status/status.facade';
import { MemberFacade } from '../+state/team-chat/member/member.facade';
import { ChannelActionFacade } from '../shared/channel-action/+state/channel-action.facade';
import { ConverterService } from './converter.service';
import { expand, map, switchMap, tap } from 'rxjs/operators';
import { TeamChatService } from '../data-access/team-chat/team-chat.service';
import { NotificationService } from '../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class StreamChatService {
  client: StreamChat;
  readonly unmuteTimeouts: Map<string, number> = new Map<string, number>();

  private channels: Map<string, StreamChatChannel> = new Map<
    string,
    StreamChatChannel
  >();

  constructor(
    @Inject(APP_CONFIGURATION) private readonly config: AppConfiguration,
    private readonly channelsFacade: ChannelsFacade,
    private readonly streamChatFacade: StreamChatFacade,
    private readonly channelFacade: ChannelFacade,
    private readonly statusFacade: StatusFacade,
    private readonly memberFacade: MemberFacade,
    private readonly channelActionFacade: ChannelActionFacade,
    private readonly converterService: ConverterService,
    private readonly teamChatService: TeamChatService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService
  ) {
    this.client = new StreamChat(environment.teamChat.key, {
      timeout: environment.teamChat.timeout
    });

    this.addErrorInterceptor();
  }

  connectUser(
    teamMember: TeamMember,
    token: string
  ): Observable<boolean | void | ConnectionOpen> {
    // eslint-disable-next-line no-underscore-dangle
    return this.client._hasConnectionID()
      ? of(true)
      : from(
          this.client.connectUser(
            {
              id: teamMember.id.toString(),
              name: teamMember.name,
              image: teamMember.image
            },
            token
          )
        );
  }

  disconnectUser(): Observable<void> {
    return from(this.client.disconnectUser());
  }

  fetchChannels(
    filterConditions: ChannelFilters = {},
    sort: ChannelSort = {},
    options: ChannelOptions = {
      message_limit: this.config.channel.messagesPerPage
    }
  ): Observable<StreamChatChannel[]> {
    let offset = 0;
    const chunkSize = 30; // 30 is max number of channels that StreamChat returns
    const channels: StreamChatChannel[] = [];

    return this.getChannelsChunk(
      filterConditions,
      sort,
      options,
      offset,
      chunkSize
    ).pipe(
      tap((channelsChunk) => {
        channels.push(...channelsChunk);
      }),
      expand((result) => {
        offset += chunkSize;

        return result.length < chunkSize
          ? []
          : this.getChannelsChunk(
              filterConditions,
              sort,
              options,
              offset,
              chunkSize
            ).pipe(
              tap((channelsChunk) => {
                channels.push(...channelsChunk);
              })
            );
      }),
      map(() => channels)
    );
  }

  loadMoreMessages(channelId: string): Observable<ChannelAPIResponse> {
    const channel: Channel = this.getChannelById(channelId);
    return from(
      channel.query({
        messages: {
          limit: this.config.channel.messagesPerPage,
          id_lt: channel.state.messages[0]?.id
        }
      })
    );
  }

  /**
   * Adds a new channel (only when a new conversation has just started)
   *
   * @param channel
   */
  addChannel(channel: StreamChatChannel): void {
    if (this.channels.get(channel.id)) {
      return;
    }

    this.channels.set(channel.id, channel);
    this.handleMuteStatus(channel);
  }

  getChannels(): Channel[] {
    return Array.from(this.channels.values());
  }

  createChannel(
    memberIds: string[],
    type: ChannelTypes = ChannelTypes.MESSAGING
  ): Observable<ChannelAPIResponse> {
    const channel = this.client.channel(type, `${memberIds[0]}_${uuidv4()}`, {
      members: memberIds
    });

    if (type === ChannelTypes.MESSAGING) {
      return from(channel.create());
    }

    return from(channel.create()).pipe(
      switchMap((response) =>
        this.teamChatService
          .addModerator(
            response.channel.id,
            ChannelTypes.TEAM,
            this.client.user.id
          )
          .pipe(map(() => response))
      )
    );
  }

  updateChannel(
    channelId: string,
    updateChannel: PartialUpdateChannel
  ): Observable<UpdateChannelAPIResponse> {
    return from(this.getChannelById(channelId).updatePartial(updateChannel));
  }

  deleteChannel(channelId: string): Observable<DeleteChannelAPIResponse> {
    return from(this.getChannelById(channelId).delete());
  }

  startTyping(channelId: string): Observable<void> {
    return from(this.getChannelById(channelId).keystroke());
  }

  stopTyping(channelId: string): Observable<void> {
    return from(this.getChannelById(channelId).stopTyping());
  }

  markChannelAsRead(channelId: string): Observable<APIResponse> {
    return from(this.getChannelById(channelId).markRead());
  }

  muteChannel(channelId: string, duration: number): Observable<APIResponse> {
    return from(
      this.getChannelById(channelId).mute({
        expiration: duration
      })
    );
  }

  unmuteChannel(channelId: string): Observable<APIResponse> {
    return from(this.getChannelById(channelId).unmute());
  }

  hideChannel(channelId: string): Observable<APIResponse> {
    return from(this.getChannelById(channelId).hide());
  }

  showChannel(channelId: string): Observable<APIResponse> {
    return from(this.getChannelById(channelId).show());
  }

  archiveChannel(
    channelId: string,
    channelType: ChannelTypes,
    userId: string
  ): Observable<boolean> {
    return this.teamChatService.archiveChannel(channelId, channelType, userId);
  }

  sendMessage(
    channelId: string,
    text: string,
    silent: boolean = false
  ): Observable<APIResponse> {
    return from(
      this.getChannelById(channelId).sendMessage({
        text,
        silent
      })
    );
  }

  fetchUsers(ids: string[]): Observable<QueryUsersResponse> {
    return from(
      this.client.queryUsers({ id: { $in: ids } }, {}, { presence: true })
    );
  }

  addMembers(
    channelId: string,
    ids: string[],
    message: string
  ): Observable<UpdateChannelAPIResponse> {
    return from(
      this.getChannelById(channelId).addMembers(ids, {
        text: message,
        silent: true
      })
    );
  }

  removeMembers(
    channelId: string,
    ids: string[],
    message: string
  ): Observable<UpdateChannelAPIResponse> {
    return from(
      this.getChannelById(channelId).removeMembers(ids, {
        text: message,
        silent: true
      })
    );
  }

  subscribeToChannelEvents(channelId: string): void {
    this.unsubscribeFromChannelEvents(channelId);
    this.getChannelById(channelId).on(this.channelEventsCallback);
  }

  unsubscribeFromChannelEvents(channelId: string): void {
    this.getChannelById(channelId).off(this.channelEventsCallback);
  }

  subscribeToClientEvents(): void {
    this.client.on(this.clientEventsCallback);
  }

  unsubscribeFromClientEvents(): void {
    this.client.off(this.clientEventsCallback);
  }

  setUnmuteTimeout(channelId: string, duration: number): void {
    if (!duration) {
      return;
    }

    this.clearUnmuteTimeout(channelId);
    this.unmuteTimeouts.set(
      channelId,
      duration > 0
        ? window.setTimeout(() => {
            this.clearUnmuteTimeout(channelId);
            this.streamChatFacade.unmuteChannel(channelId);
          }, duration)
        : 0
    );
  }

  clearUnmuteTimeout(channelId: string): void {
    window.clearTimeout(this.unmuteTimeouts.get(channelId));
    this.unmuteTimeouts.delete(channelId);
  }

  private getChannelById(channelId: string): Channel {
    return this.channels.get(channelId);
  }

  private readonly channelEventsCallback = (event) => {
    switch (event.type) {
      case 'message.new':
        const message = this.converterService.convertStreamChatMessageToMessage(
          event.message
        );
        // add a new message to current channel (channel view)
        this.channelFacade.addMessage(message);
        // mark channel as read when the logged user is not its author
        if (event.message.user.id !== this.client.user.id) {
          this.channelActionFacade.markChannelAsRead(event.channel_id);
        }
        break;
      case 'notification.mark_read':
        this.statusFacade.setUnreadMessagesCount(event.unread_count);
        break;
      case 'channel.hidden':
        this.channelFacade.hideChannel();
        break;
      case 'channel.visible':
        this.channelFacade.showChannel();
        break;
      case 'channel.updated':
        this.channelFacade.updateChannel({
          name: event.channel.name ?? '',
          isArchived: !!event.channel.disabled
        });
        break;
      case 'member.updated':
        this.channelFacade.updateMembership(
          this.converterService.convertStreamChatMembershipToMembership(
            event.member
          )
        );
        break;
      case 'member.removed':
        this.channelFacade.removeMembership(+event.user.id);
        break;
      case 'member.added':
        this.channelFacade.addMembership(
          this.converterService.convertStreamChatMembershipToMembership(
            event.member
          )
        );
        break;
    }
  };

  private readonly clientEventsCallback = (event) => {
    switch (event.type) {
      case 'health.check':
        if (!event.me) {
          return;
        }
        this.statusFacade.setUnreadMessagesCount(event.me.unread_count);
        break;
      case 'user.watching.start':
      case 'user.watching.stop':
        if (this.client.user.id === event.user.id) {
          break;
        }
        this.memberFacade.setOnlineStatus(+event.user.id, event.user.online);
        break;
      case 'message.new':
        const message = this.converterService.convertStreamChatMessageToMessage(
          event.message
        );
        // will update the last message (channels list), when the user receives a new message
        this.channelsFacade.setLastMessage(
          event.channel_id,
          message,
          event.unread_count
        );
        break;
      case 'typing.start':
        if (this.client.user.id !== event.user.id) {
          this.channelsFacade.typingStart(event.channel_id);
        }
        break;
      case 'typing.stop':
        if (this.client.user.id !== event.user.id) {
          this.channelsFacade.typingStop(event.channel_id);
        }
        break;
      case 'notification.mark_read':
        this.channelsFacade.markChannelAsRead(event.channel_id);
        this.statusFacade.setUnreadMessagesCount(event.unread_count);
        break;
      case 'notification.message_new': // existing channel that was not being watched
        this.channelsFacade.addChannel(event.channel_id);
        this.statusFacade.setUnreadMessagesCount(event.unread_count);
        break;
      case 'notification.added_to_channel': // new channel
        this.channelsFacade.addChannel(event.channel_id);
        break;
      case 'notification.removed_from_channel':
        this.channelsFacade.removeChannel(event.channel_id);
        this.statusFacade.setUnreadMessagesCount(event.unread_count);
        break;
      case 'channel.hidden':
        this.channelsFacade.hideChannel(event.channel_id);
        break;
      case 'channel.visible':
        this.channelsFacade.showChannel(event.channel_id);
        break;
      case 'channel.deleted':
        this.unsubscribeFromChannelEvents(event.channel_id);
        this.channelsFacade.getChannels();
        break;
      case 'notification.channel_mutes_updated':
        const mutedChannels: MutedChannel[] = event.me.channel_mutes.map(
          (channelMute) => ({
            id: channelMute.channel.id,
            expiresAt: moment(channelMute.expires)
          })
        );

        this.streamChatFacade.updateMuteStatus(mutedChannels);

        break;
      case 'connection.changed':
        if (event.online) {
          this.statusFacade.goOnline();
        } else {
          this.statusFacade.goOffline();
        }
        break;
      case 'channel.updated':
        this.channelsFacade.updateChannel({
          id: event.channel.id,
          name: event.channel.name ?? '',
          isArchived: !!event.channel.disabled
        });
        break;
      case 'member.updated':
        this.channelsFacade.updateMembership(
          event.channel_id,
          this.converterService.convertStreamChatMembershipToMembership(
            event.member
          )
        );
        break;
      case 'member.removed':
        this.channelsFacade.removeMembership(event.channel_id, +event.user.id);
        break;
      case 'member.added':
        this.channelsFacade.addMembership(
          event.channel_id,
          this.converterService.convertStreamChatMembershipToMembership(
            event.member
          )
        );
        break;
      case 'user.presence.changed':
        this.memberFacade.setOnlineStatus(+event.user.id, event.user.online);
        break;
    }
  };

  private handleMuteStatus(channel: Channel): void {
    if (channel.muteStatus().muted) {
      const duration = moment(channel.muteStatus().expiresAt).diff(moment());
      this.setUnmuteTimeout(channel.id, duration);
    }
  }

  private getChannelsChunk(
    filterConditions,
    sort,
    options,
    offset,
    limit
  ): Observable<StreamChatChannel[]> {
    return from(
      this.client.queryChannels(filterConditions, sort, {
        ...options,
        limit,
        offset
      })
    );
  }

  private addErrorInterceptor(): void {
    if (!this.client) {
      return;
    }

    this.client.axiosInstance.interceptors.response.use(undefined, (error) =>
      this.handleError(error)
    );
  }

  private handleError(error): Promise<any> {
    switch (error.response?.status) {
      case 429:
        this.notificationService.show(
          this.translateService.instant('errors.too-many-requests')
        );
        break;
      default:
        this.notificationService.show(
          this.translateService.instant('errors.unknown-error')
        );
        break;
    }

    return Promise.reject(error);
  }
}
