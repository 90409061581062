import { createAction, props } from '@ngrx/store';
import { getErrorState } from '../../../common/call-state';

export const getCryptoKey = createAction('[TeamChat/CryptoKey] Get crypto key');

export const getCryptoKeySucceeded = createAction(
  '[TeamChat/CryptoKey] Get crypto key succeeded',
  props<{ cryptoKey: string }>()
);

export const getCryptoKeyFailed = createAction(
  '[TeamChat/CryptoKey] Get crypto key failed',
  getErrorState
);
