import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelativeDatePipe } from './relative-date.pipe';
import { HighlightPipe } from './highlight.pipe';
import { LastMessagePipe } from './last-message.pipe';
import { MessageDatePipe } from './message-date.pipe';

@NgModule({
  declarations: [
    RelativeDatePipe,
    HighlightPipe,
    LastMessagePipe,
    MessageDatePipe
  ],
  imports: [CommonModule],
  providers: [RelativeDatePipe, HighlightPipe, LastMessagePipe],
  exports: [RelativeDatePipe, HighlightPipe, LastMessagePipe, MessageDatePipe]
})
export class PipesModule {}
