import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addChannelSucceeded,
  addMembershipFailed,
  addMembershipSucceeded,
  getChannels,
  getChannelsFailed,
  getChannelsSucceeded,
  muteAllChannelsFailed,
  removeChannelSucceeded,
  removeMembershipFailed,
  removeMembershipSucceeded,
  showHiddenChannels,
  showVisibleChannels,
  unmuteAllChannelsFailed,
  updateChannelSucceeded,
  updateMembershipFailed,
  updateMembershipSucceeded
} from './channels.actions';
import { Channel, MemberRole } from '../../app.types';
import { CallState, LoadingState } from '../../common/call-state';

export const FEATURE_KEY = 'channels';

export const entityAdapter = createEntityAdapter<Channel>();

export interface State extends EntityState<Channel> {
  areHiddenChannelsVisible: boolean;
  callState: CallState;
}

export const initialState: State = entityAdapter.getInitialState({
  areHiddenChannelsVisible: false,
  callState: LoadingState.INIT
});

const channelsReducer = createReducer(
  initialState,
  on(getChannels, (state) => ({
    ...state,
    callState: LoadingState.LOADING
  })),
  on(getChannelsSucceeded, (state, { channels }) =>
    entityAdapter.setAll(channels, { ...state, callState: LoadingState.LOADED })
  ),
  on(addChannelSucceeded, (state, { channel }) =>
    entityAdapter.addOne(channel, state)
  ),
  on(updateChannelSucceeded, (state, { channel }) =>
    entityAdapter.updateOne(
      {
        id: channel.id,
        changes: channel
      },
      { ...state }
    )
  ),
  on(removeChannelSucceeded, (state, { channelId }) =>
    entityAdapter.removeOne(channelId, { ...state })
  ),
  on(showHiddenChannels, (state) => ({
    ...state,
    areHiddenChannelsVisible: true
  })),
  on(showVisibleChannels, (state) => ({
    ...state,
    areHiddenChannelsVisible: false
  })),
  on(
    getChannelsFailed,
    updateMembershipFailed,
    addMembershipFailed,
    removeMembershipFailed,
    muteAllChannelsFailed,
    unmuteAllChannelsFailed,
    (state, error) => ({
      ...state,
      callState: error
    })
  ),
  on(updateMembershipSucceeded, (state, { channelId, membership }) => {
    const channel = {
      ...entityAdapter.getSelectors().selectEntities(state)[channelId]
    };

    if (!Object.keys(channel).length) {
      return { ...state };
    }

    const currentMembership = { ...channel.membership };
    const memberRoles = { ...channel.memberRoles };
    const moderatorIds = [...channel.moderatorIds];

    if (currentMembership.memberId === membership.memberId) {
      currentMembership.role = membership.role;
    }

    if (
      membership.role === MemberRole.MODERATOR &&
      !moderatorIds.includes(membership.memberId)
    ) {
      moderatorIds.push(membership.memberId);
    }

    if (
      membership.role !== MemberRole.MODERATOR &&
      moderatorIds.includes(membership.memberId)
    ) {
      moderatorIds.splice(moderatorIds.indexOf(membership.memberId), 1);
    }

    memberRoles[membership.memberId] = membership.role;

    return entityAdapter.updateOne(
      {
        id: channelId,
        changes: {
          membership: currentMembership,
          moderatorIds,
          memberRoles
        }
      },
      { ...state }
    );
  }),
  on(addMembershipSucceeded, (state, { channelId, membership }) => {
    const channel = {
      ...entityAdapter.getSelectors().selectEntities(state)[channelId]
    };

    if (!Object.keys(channel).length) {
      return { ...state };
    }

    const memberRoles = { ...channel.memberRoles };
    const memberIds = [...channel.memberIds];

    memberIds.push(membership.memberId);
    memberRoles[membership.memberId] = membership.role;

    return entityAdapter.updateOne(
      {
        id: channelId,
        changes: {
          memberIds,
          memberRoles
        }
      },
      { ...state }
    );
  }),
  on(removeMembershipSucceeded, (state, { channelId, memberId }) => {
    const channel = {
      ...entityAdapter.getSelectors().selectEntities(state)[channelId]
    };

    if (!Object.keys(channel).length) {
      return { ...state };
    }

    const memberRoles = { ...channel.memberRoles };
    const memberIds = [...channel.memberIds];

    memberIds.splice(memberIds.indexOf(memberId), 1);
    delete memberRoles[memberId];

    return entityAdapter.updateOne(
      {
        id: channelId,
        changes: {
          memberIds,
          memberRoles
        }
      },
      { ...state }
    );
  })
);

export const reducer = (state: State, action: Action): State => {
  return channelsReducer(state, action);
};
