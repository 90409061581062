import { Action, createReducer, on } from '@ngrx/store';
import {
  initializeStreamChat,
  initializeStreamChatFailed,
  initializeStreamChatSucceeded
} from './stream-chat.actions';
import { CallState, LoadingState } from '../../../common/call-state';

export const FEATURE_KEY = 'stream-chat';

export interface State {
  callState: CallState;
}

export const initialState: State = {
  callState: LoadingState.INIT
};

const streamChatReducer = createReducer(
  initialState,
  on(initializeStreamChat, (state) => ({
    ...state,
    callState: LoadingState.LOADING
  })),
  on(initializeStreamChatSucceeded, (state) => ({
    ...state,
    callState: LoadingState.LOADED
  })),
  on(initializeStreamChatFailed, (state, error) => ({
    ...state,
    callState: error
  }))
);

export const reducer = (state: State, action: Action): State => {
  return streamChatReducer(state, action);
};
