import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY, reducer } from './practice.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PracticeEffects } from './practice.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([PracticeEffects])
  ]
})
export class PracticeStateModule {}
