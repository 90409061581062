import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StatusFacade } from './status.facade';
import {
  decreaseUnreadMessagesCount,
  goOffline,
  goOfflineFailed,
  goOfflineSucceeded,
  goOnline,
  goOnlineFailed,
  goOnlineSucceeded,
  increaseUnreadMessagesCount,
  setIsMobileApp,
  setIsMobileAppFailed,
  setIsMobileAppSucceed,
  setPreviousUrl,
  setPreviousUrlFailed,
  setPreviousUrlSucceeded,
  setUnreadMessagesCount
} from './status.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CommunicationService } from '../../services/communication/communication.service';
import { of } from 'rxjs';
import { CommunicationEventType } from '@localmed/modento-team-chat-types';

@Injectable()
export class StatusEffects {
  unreadMessagesCountChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          setUnreadMessagesCount,
          increaseUnreadMessagesCount,
          decreaseUnreadMessagesCount
        ),
        switchMap(() =>
          this.statusFacade.unreadMessagesCount$.pipe(
            tap((counterValue) => {
              this.communicationService.sendEvent({
                type: CommunicationEventType.UNREAD_MESSAGES_COUNTER_UPDATE,
                data: {
                  counterValue
                }
              });
            })
          )
        )
      ),
    { dispatch: false }
  );

  setPreviousUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPreviousUrl),
      map(({ url }) => setPreviousUrlSucceeded({ url })),
      catchError((error) => of(setPreviousUrlFailed(error)))
    )
  );

  goOnline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goOnline),
      map(() => goOnlineSucceeded()),
      catchError((error) => of(goOnlineFailed(error)))
    )
  );

  goOffline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goOffline),
      map(() => goOfflineSucceeded()),
      catchError((error) => of(goOfflineFailed(error)))
    )
  );

  setIsMobileApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setIsMobileApp),
      map(({ isMobileApp }) => setIsMobileAppSucceed({ isMobileApp })),
      catchError((error) => of(setIsMobileAppFailed(error)))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly statusFacade: StatusFacade,
    private readonly communicationService: CommunicationService
  ) {}
}
