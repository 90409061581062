import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private readonly translateService: TranslateService
  ) {}

  transform(date: moment.Moment, ...args: unknown[]): string {
    if (!date) {
      return '';
    }

    const now = moment();

    if (date.isSame(now, 'day')) {
      return formatDate(date.toDate(), 'shortTime', this.locale);
    }

    if (date.isSame(now.subtract(1, 'day'), 'day')) {
      return this.translateService.instant('relative-date-pipe.yesterday');
    }

    return formatDate(date.toDate(), 'MM.dd.YYYY', this.locale);
  }
}
