import { createAction, props } from '@ngrx/store';
import { getErrorState } from '../../common/call-state';

export const initApplication = createAction('[Status] Init');
export const destroyApplication = createAction('[Status] Destroy');

export const setUnreadMessagesCount = createAction(
  '[Status] Set unread message count',
  props<{ unreadMessagesCount: number }>()
);
export const increaseUnreadMessagesCount = createAction(
  '[Status] Increase unread messages count'
);
export const decreaseUnreadMessagesCount = createAction(
  '[Status] Decrease unread messages count'
);

export const setPreviousUrl = createAction(
  '[Status] Set previous URL',
  props<{ url: string }>()
);
export const setPreviousUrlSucceeded = createAction(
  '[Status] Set previous URL succeeded',
  props<{ url: string }>()
);
export const setPreviousUrlFailed = createAction(
  '[Status] Set previous URL failed',
  getErrorState
);

export const goOffline = createAction('[Status] Go offline');
export const goOfflineSucceeded = createAction('[Status] Go offline succeeded');
export const goOfflineFailed = createAction(
  '[Status] Go offline failed',
  getErrorState
);
export const goOnline = createAction('[StreamChat] Go online');
export const goOnlineSucceeded = createAction(
  '[StreamChat] Go online succeeded'
);
export const goOnlineFailed = createAction(
  '[StreamChat] Go online failed',
  getErrorState
);

export const setIsMobileApp = createAction(
  '[Status] Set isMobileApp',
  props<{ isMobileApp: boolean }>()
);
export const setIsMobileAppSucceed = createAction(
  '[Status] Set isMobileApp succeeded',
  props<{ isMobileApp: boolean }>()
);
export const setIsMobileAppFailed = createAction(
  '[Status] Set isMobileApp failed',
  getErrorState
);
