import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  createNewChannel,
  createNewChannelFailed,
  createNewChannelSucceeded
} from '../../../new-channel/+state/new-channel.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  createNewGroupChannel,
  createNewGroupChannelFailed,
  createNewGroupChannelSucceeded
} from '../../../new-group-channel/+state/new-group-channel.actions';
import { StreamChatService } from '../../stream-chat.service';
import { UserFacade } from '../../../+state/user/user.facade';
import { Injectable } from '@angular/core';
import { ChannelTypes } from '../../stream-chat.types';

@Injectable()
export class StreamChatCreateChannelEffects {
  createNewChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewChannel),
      withLatestFrom(this.userFacade.user$),
      switchMap(([action, user]) =>
        this.streamChatService
          .fetchChannels({
            members: {
              $eq: [user.team_member.id.toString(), action.memberId.toString()]
            },
            type: ChannelTypes.MESSAGING,
            $or: [{ hidden: true }, { hidden: false }]
          })
          .pipe(
            switchMap(([existingChannel]) => {
              if (existingChannel) {
                return of(
                  createNewChannelSucceeded({ channelId: existingChannel.id })
                );
              }

              return this.streamChatService
                .createChannel([
                  user.team_member.id.toString(),
                  action.memberId.toString()
                ])
                .pipe(
                  map(({ channel }) =>
                    createNewChannelSucceeded({ channelId: channel.id })
                  )
                );
            }),
            catchError((error) => of(createNewChannelFailed(error)))
          )
      )
    )
  );

  createNewGroupChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewGroupChannel),
      withLatestFrom(this.userFacade.user$),
      switchMap(([action, user]) =>
        this.streamChatService
          .createChannel(
            [
              user.team_member.id.toString(),
              ...action.memberIds.map((id) => id.toString())
            ],
            ChannelTypes.TEAM
          )
          .pipe(
            map(({ channel }) =>
              createNewGroupChannelSucceeded({ channelId: channel.id })
            ),
            catchError((error) => of(createNewGroupChannelFailed(error)))
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly streamChatService: StreamChatService,
    private readonly userFacade: UserFacade
  ) {}
}
