import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Member } from './member.types';
import { CallState, LoadingState } from '../../../common/call-state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getMemberFailed,
  getMembers,
  getMembersSucceeded,
  setOnlineStatusFailed,
  setOnlineStatusSucceeded
} from './member.actions';

export const FEATURE_KEY = 'members';

export const entityAdapter = createEntityAdapter<Member>();

export interface State extends EntityState<Member> {
  callState: CallState;
}

export const initialState: State = entityAdapter.getInitialState({
  callState: LoadingState.INIT
});

const memberReducer = createReducer(
  initialState,
  on(getMembers, (state) => ({
    ...state,
    callState: LoadingState.LOADING
  })),
  on(getMembersSucceeded, (state, { members }) =>
    entityAdapter.setAll(members, { ...state, callState: LoadingState.LOADED })
  ),
  on(setOnlineStatusSucceeded, (state, { memberId, isOnline }) =>
    entityAdapter.updateOne(
      {
        id: memberId,
        changes: {
          isOnline
        }
      },
      { ...state }
    )
  ),
  on(getMemberFailed, setOnlineStatusFailed, (state, error) => ({
    ...state,
    callState: error
  }))
);

export const reducer = (state: State, action: Action): State => {
  return memberReducer(state, action);
};
