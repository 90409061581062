import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  archiveChannel,
  archiveChannelFailed,
  archiveChannelSucceeded,
  hideChannel,
  hideChannelFailed,
  hideChannelSucceeded,
  markChannelAsRead,
  markChannelAsReadFailed,
  markChannelAsReadSucceeded,
  muteChannel,
  muteChannelFailed,
  muteChannelSucceeded,
  showChannel,
  showChannelFailed,
  showChannelSucceeded,
  unmuteChannel,
  unmuteChannelFailed,
  unmuteChannelSucceeded
} from '../../../shared/channel-action/+state/channel-action.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import { StreamChatService } from '../../stream-chat.service';
import { CryptoKeyFacade } from '../../../+state/team-chat/crypto-key/crypto-key.facade';
import { UserFacade } from '../../../+state/user/user.facade';
import { ChannelFacade } from '../../../+state/channel/channel.facade';
import { ChannelsFacade } from '../../../+state/channels/channels.facade';

@Injectable()
export class StreamChatChannelActionsEffects {
  markChannelsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markChannelAsRead),
      switchMap((action) =>
        this.streamChatService.markChannelAsRead(action.channelId).pipe(
          map(() => markChannelAsReadSucceeded()),
          catchError((error) => of(markChannelAsReadFailed(error)))
        )
      )
    )
  );

  muteChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(muteChannel),
      mergeMap((action) =>
        this.streamChatService
          .muteChannel(action.channelId, action.duration)
          .pipe(
            map(() => muteChannelSucceeded()),
            catchError((error) => of(muteChannelFailed(error)))
          )
      )
    )
  );

  unmuteChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unmuteChannel),
      mergeMap((action) =>
        this.streamChatService.unmuteChannel(action.channelId).pipe(
          withLatestFrom(this.channelFacade.channel$),
          tap(([response, channel]) => {
            // in order to improve UX we unmute channel manually (unmute event from GetStream is missing sometimes)
            if (channel.id === action.channelId) {
              this.channelFacade.unmuteChannel();
            }
            this.channelsFacade.unmuteChannel(action.channelId);
          }),
          map(() => unmuteChannelSucceeded()),
          catchError((error) => of(unmuteChannelFailed(error)))
        )
      )
    )
  );

  hideChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hideChannel),
      switchMap((action) =>
        this.streamChatService.hideChannel(action.channelId).pipe(
          map(() => hideChannelSucceeded()),
          catchError((error) => of(hideChannelFailed(error)))
        )
      )
    )
  );

  showChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showChannel),
      switchMap((action) =>
        this.streamChatService.showChannel(action.channelId).pipe(
          map(() => showChannelSucceeded()),
          catchError((error) => of(showChannelFailed(error)))
        )
      )
    )
  );

  archiveChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(archiveChannel),
      withLatestFrom(this.userFacade.user$),
      switchMap(([{ channelId, channelType }, user]) =>
        this.streamChatService
          .archiveChannel(
            channelId,
            channelType,
            user.team_member.id.toString()
          )
          .pipe(
            map(() => archiveChannelSucceeded()),
            catchError((error) => of(archiveChannelFailed(error)))
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly streamChatService: StreamChatService,
    private readonly cryptoKeyFacade: CryptoKeyFacade,
    private readonly userFacade: UserFacade,
    private readonly channelFacade: ChannelFacade,
    private readonly channelsFacade: ChannelsFacade
  ) {}
}
