import { createAction, props } from '@ngrx/store';
import { getErrorState } from '../../common/call-state';

export const createNewGroupChannel = createAction(
  '[NewGroupChannel] Create a new group channel',
  props<{ memberIds: number[] }>()
);
export const createNewGroupChannelSucceeded = createAction(
  '[NewGroupChannel] Create a new group channel succeeded',
  props<{ channelId: string }>()
);
export const createNewGroupChannelFailed = createAction(
  '[NewGroupChannel] Create a new group channel failed',
  getErrorState
);

export const clearCreatedChannelId = createAction(
  '[NewGroupChannel] Clear created channel id'
);
export const clearCreatedChannelIdSucceeded = createAction(
  '[NewGroupChannel] Clear created channel id succeeded'
);
export const clearCreatedChannelIdFailed = createAction(
  '[NewGroupChannel] Clear created channel id failed',
  getErrorState
);
