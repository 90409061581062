import {
  ChangeDetectionStrategy,
  Component,
  HostListener
} from '@angular/core';
import { CommunicationService } from '../services/communication/communication.service';
import { NavigationService } from '../services/naviagation/navigation.service';
import {
  IncomingPostMessageEvent,
  IncomingPostMessageEventType,
  PostMessageData
} from '@localmed/modento-team-chat-types';

/**
 * This component is a wrapper and was created for two main reasons:
 * 1. Angular's navigation starts with / path and we are not able to inject NavigationService there (it requires valid url - practiceGuid + authToken
 * 2. handling window:message event should be available from any view
 */
@Component({
  selector: 'app-team-chat',
  templateUrl: './team-chat.component.html',
  styleUrls: ['./team-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamChatComponent {
  constructor(
    private readonly communicationService: CommunicationService,
    private readonly navigationService: NavigationService
  ) {}

  @HostListener('window:message', ['$event'])
  onPostMessage(event): void {
    if (
      !this.communicationService.checkIfPostMessageComesFromDashboard(
        event.origin
      )
    ) {
      return;
    }

    switch ((event.data as IncomingPostMessageEvent<PostMessageData>).type) {
      case IncomingPostMessageEventType.GO_TO_CHANNELS:
        this.navigationService.goToChannels();
        break;
      case IncomingPostMessageEventType.GO_TO_CHANNEL:
        this.navigationService.goToChannel(event.data.data.channelId);
        break;
    }
  }
}
