import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY, reducer } from './status.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StatusEffects } from './status.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([StatusEffects])
  ]
})
export class StatusStateModule {}
