<div class="test d-flex flex-column align-items-center">
  <img ngSrc='assets/images/di_logo_transparent_background.png'
       [height]='48'
       [width]='48'
       alt='Dental Intelligence Logo'>
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
