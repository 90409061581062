import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY, reducer } from './channels.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ChannelsEffects } from './channels.effects';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  imports: [
    PipesModule,
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([ChannelsEffects])
  ]
})
export class ChannelsStateModule {}
