import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { User, UserService } from '../../data-access/user';
import { loadUser, loadUserFailed, loadUserSucceeded } from './user.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      switchMap((action) =>
        this.userService.getUser().pipe(
          map((user: User) => loadUserSucceeded({ user })),
          catchError((error) => of(loadUserFailed(error)))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService
  ) {}
}
