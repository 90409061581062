import { createAction, props } from '@ngrx/store';
import { Channel, Membership, Message } from '../../app.types';
import { getErrorState } from '../../common/call-state';
import { UpdateChannel } from './channels.types';

export const getChannels = createAction('[ChannelList/Channels] Get channels');
export const getChannelsSucceeded = createAction(
  '[ChannelList/Channels] Get channels succeeded',
  props<{ channels: Channel[] }>()
);
export const getChannelsFailed = createAction(
  '[ChannelList/Channels] Get channels failed',
  getErrorState
);

export const muteAllChannels = createAction(
  '[ChannelList/Channels] Mute all channels',
  props<{ duration: number }>()
);
export const muteAllChannelsSucceeded = createAction(
  '[ChannelList/Channels] Mute all channels succeeded'
);
export const muteAllChannelsFailed = createAction(
  '[ChannelList/Channels] Mute all channels failed',
  getErrorState
);

export const unmuteAllChannels = createAction(
  '[ChannelList/Channels] Unmute all channels'
);
export const unmuteAllChannelsSucceeded = createAction(
  '[ChannelList/Channels] Unmute all channels succeeded'
);
export const unmuteAllChannelsFailed = createAction(
  '[ChannelList/Channels] Unmute all channels failed',
  getErrorState
);

export const updateChannel = createAction(
  '[ChannelList/Channel] Update channel',
  props<{ channel: UpdateChannel }>()
);

export const updateChannelSucceeded = createAction(
  '[ChannelList/Channel] Update channel succeeded',
  props<{ channel: UpdateChannel }>()
);

export const updateChannelFailed = createAction(
  '[ChannelList/Channel] Update channel failed'
);

export const showHiddenChannels = createAction(
  '[ChannelList/Channels] Show hidden channels'
);

export const showVisibleChannels = createAction(
  '[ChannelList/Channels] Show visible channels'
);

export const addChannel = createAction(
  '[ChannelList/Channel] Add channel',
  props<{ channelId: string }>()
);
export const addChannelSucceeded = createAction(
  '[ChannelList/Channel] Add channel succeeded',
  props<{ channel: Channel }>()
);
export const addChannelFailed = createAction(
  '[ChannelList/Channel] Add channel failed',
  getErrorState
);

export const removeChannel = createAction(
  '[ChannelList/Channel] Remove channel',
  props<{ channelId: string }>()
);
export const removeChannelSucceeded = createAction(
  '[ChannelList/Channel] Remove channel succeeded',
  props<{ channelId: string }>()
);
export const removeChannelFailed = createAction(
  '[ChannelList/Channel] Remove channel failed',
  getErrorState
);

export const setLastMessage = createAction(
  '[ChannelList/Channel] Set last message',
  props<{
    channelId: string;
    lastMessage: Message;
    unreadMessagesCount: number;
  }>()
);

export const updateMembership = createAction(
  '[Channels/Members] Update membership',
  props<{ channelId: string; membership: Membership }>()
);
export const updateMembershipSucceeded = createAction(
  '[Channels/Members] Update membership succeeded',
  props<{ channelId: string; membership: Membership }>()
);
export const updateMembershipFailed = createAction(
  '[Channels/Members] Update membership failed',
  getErrorState
);

export const addMembership = createAction(
  '[Channels/Members] Add membership',
  props<{ channelId: string; membership: Membership }>()
);
export const addMembershipSucceeded = createAction(
  '[Channels/Members] Add membership succeeded',
  props<{ channelId: string; membership: Membership }>()
);
export const addMembershipFailed = createAction(
  '[Channels/Members] Add membership failed',
  getErrorState
);

export const removeMembership = createAction(
  '[Channels/Members] Remove membership',
  props<{ channelId: string; memberId: number }>()
);
export const removeMembershipSucceeded = createAction(
  '[Channels/Members] Remove membership succeeded',
  props<{ channelId: string; memberId: number }>()
);
export const removeMembershipFailed = createAction(
  '[Channels/Members] Remove membership failed',
  getErrorState
);
