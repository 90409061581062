import { CallState, LoadingState } from '../../../common/call-state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  designateModeratorAndLeaveChannel,
  designateModeratorAndLeaveChannelFailed,
  designateModeratorAndLeaveChannelSucceeded,
  hideChannelFailed,
  leaveChannel,
  leaveChannelFailed,
  leaveChannelSucceeded,
  muteChannelFailed,
  showChannelFailed,
  unmuteChannelFailed
} from './channel-action.actions';

export const FEATURE_KEY = 'channel-actions';

export interface State {
  callState: CallState;
}

export const initialState: State = {
  callState: LoadingState.INIT
};

const channelActionsReducer = createReducer(
  initialState,
  on(leaveChannel, designateModeratorAndLeaveChannel, (state) => ({
    ...state,
    callState: LoadingState.LOADING
  })),
  on(
    leaveChannelSucceeded,
    designateModeratorAndLeaveChannelSucceeded,
    (state) => ({
      ...state,
      callState: LoadingState.LOADED
    })
  ),
  on(
    leaveChannelFailed,
    designateModeratorAndLeaveChannelFailed,
    unmuteChannelFailed,
    muteChannelFailed,
    showChannelFailed,
    hideChannelFailed,
    (state, error) => ({
      ...state,
      callState: error
    })
  )
);

export const reducer = (state: State, action: Action): State => {
  return channelActionsReducer(state, action);
};
