import { Action, createReducer, on } from '@ngrx/store';
import {
  loadPractice,
  loadPracticeFailed,
  loadPracticeSucceeded
} from './practice.actions';
import { Practice } from '../../data-access/practice';
import { CallState, LoadingState } from '../../common/call-state';

export const FEATURE_KEY = 'practice';

export interface State {
  callState: CallState;
  practiceGuid: string | null;
  practice: Practice | null;
}

export const initialState: State = {
  callState: LoadingState.INIT,
  practiceGuid: null,
  practice: null
};

const practiceReducer = createReducer(
  initialState,
  on(loadPractice, (state, { practiceGuid }) => ({
    ...state,
    callState: LoadingState.LOADING,
    practiceGuid
  })),
  on(loadPracticeSucceeded, (state, { practice }) => ({
    ...state,
    callState: LoadingState.LOADED,
    practice
  })),
  on(loadPracticeFailed, (state, error) => ({
    ...state,
    callState: error
  }))
);

export const reducer = (state: State, action: Action): State => {
  return practiceReducer(state, action);
};
