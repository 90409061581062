import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  initializeStreamChat,
  initializeStreamChatFailed,
  initializeStreamChatSucceeded,
  unmuteChannel,
  unmuteChannelSucceeded,
  updateMuteStatus,
  updateMuteStatusSucceeded,
} from './stream-chat.actions';
import {
  catchError,
  delay,
  filter,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { UserFacade } from '../../../+state/user/user.facade';
import { TeamChatService } from '../../../data-access/team-chat/team-chat.service';
import { StreamChatService } from '../../stream-chat.service';
import { ChannelsFacade } from '../../../+state/channels/channels.facade';
import { SecurityService } from '../../../services/security/security.service';
import { StreamChatFacade } from './stream-chat.facade';
import { ConverterService } from '../../converter.service';
import {
  addChannel,
  addChannelFailed,
  addChannelSucceeded,
  muteAllChannels,
  muteAllChannelsFailed,
  muteAllChannelsSucceeded,
  unmuteAllChannels,
  unmuteAllChannelsFailed,
  unmuteAllChannelsSucceeded,
} from '../../../+state/channels/channels.actions';
import {
  deleteChannel,
  deleteChannelFailed,
  deleteChannelSucceeded,
} from '../../../shared/channel-action/+state/channel-action.actions';
import * as moment from 'moment';
import { ChannelFacade } from '../../../+state/channel/channel.facade';
import {
  destroyApplication,
  goOffline,
  goOfflineSucceeded,
  initApplication,
} from '../../../+state/status/status.actions';
import { Router } from '@angular/router';
import { StatusFacade } from '../../../+state/status/status.facade';
import { ErrorPageRedirectReason } from '../../../app.types';

@Injectable()
export class StreamChatEffects {
  goOffline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goOffline),
      delay(2000),
      withLatestFrom(this.statusFacade.online$),
      filter(([action, online]) => !online),
      tap(() =>
        this.router.navigate(['error'], {
          state: { reason: ErrorPageRedirectReason.GET_STREAM_OFFLINE },
        })
      ),
      map(() => goOfflineSucceeded())
    )
  );

  subscribeToClientEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initApplication),
        tap(() => this.streamChatService.subscribeToClientEvents())
      ),
    { dispatch: false }
  );

  disconnectUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(destroyApplication),
        tap(() => {
          this.streamChatService.unsubscribeFromClientEvents();
        }),
        switchMap(() => this.streamChatService.disconnectUser())
      ),
    { dispatch: false }
  );

  initializeStreamChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeStreamChat),
      switchMap(() =>
        combineLatest([
          this.teamChatService.syncCurrentUser(),
          this.userFacade.user$,
        ]).pipe(
          switchMap(([token, user]) =>
            this.streamChatService
              .connectUser(user.team_member, token)
              .pipe(map(() => initializeStreamChatSucceeded()))
          ),
          catchError((error) => of(initializeStreamChatFailed(error)))
        )
      )
    )
  );

  addChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addChannel),
      switchMap((action) =>
        this.streamChatService.fetchChannels({ id: action.channelId }).pipe(
          tap(([streamChatChannel]) => {
            this.streamChatService.addChannel(streamChatChannel);
          }),
          map(([streamChatChannel]) =>
            addChannelSucceeded({
              channel:
                this.converterService.convertStreamChatChannelToChannel(
                  streamChatChannel
                ),
            })
          ),
          catchError((error) => of(addChannelFailed(error)))
        )
      )
    )
  );

  muteAllChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(muteAllChannels),
      switchMap((action) =>
        combineLatest(
          this.streamChatService
            .getChannels()
            .map((channel) =>
              this.streamChatService.muteChannel(channel.id, action.duration)
            )
        ).pipe(
          map(() => muteAllChannelsSucceeded()),
          catchError((error) => of(muteAllChannelsFailed(error)))
        )
      )
    )
  );

  unmuteAllChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unmuteAllChannels),
      switchMap(() =>
        combineLatest(
          this.streamChatService
            .getChannels()
            .filter((channel) => channel.muteStatus().muted)
            .map((channel) => this.streamChatService.unmuteChannel(channel.id))
        ).pipe(
          map(() => unmuteAllChannelsSucceeded()),
          catchError((error) => of(unmuteAllChannelsFailed(error)))
        )
      )
    )
  );

  updateMuteStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMuteStatus),
      withLatestFrom(this.channelFacade.channel$),
      switchMap(([{ mutedChannels }, channel]) => {
        Array.from(this.streamChatService.unmuteTimeouts.keys()).forEach(
          (key) => {
            const mutedChannel = mutedChannels.find(({ id }) => id === key);

            if (mutedChannel) {
              const duration = moment(mutedChannel.expiresAt).diff(moment());
              this.streamChatService.setUnmuteTimeout(key, duration);
            } else {
              this.streamChatService.clearUnmuteTimeout(key);
              this.channelsFacade.unmuteChannel(key);

              if (channel && channel.id === key) {
                this.channelFacade.unmuteChannel();
              }
            }
          }
        );

        mutedChannels.forEach((mutedChannel) => {
          if (this.streamChatService.unmuteTimeouts.get(mutedChannel.id)) {
            return;
          }

          const duration = moment(mutedChannel.expiresAt).diff(moment());
          this.streamChatService.setUnmuteTimeout(mutedChannel.id, duration);
          this.channelsFacade.muteChannel(
            mutedChannel.id,
            duration && duration > 0 ? moment(mutedChannel.expiresAt) : null
          );

          if (channel && channel.id === mutedChannel.id) {
            this.channelFacade.muteChannel(
              duration && duration > 0 ? moment(mutedChannel.expiresAt) : null
            );
          }
        });

        return of(updateMuteStatusSucceeded());
      })
    )
  );

  deleteChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteChannel),
      switchMap((action) =>
        this.streamChatService.deleteChannel(action.channelId).pipe(
          map((channel) => deleteChannelSucceeded()),
          catchError((error) => of(deleteChannelFailed(error)))
        )
      )
    )
  );

  unmuteChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unmuteChannel),
      withLatestFrom(this.channelFacade.channel$),
      tap(([{ channelId }, channel]) => {
        this.channelsFacade.unmuteChannel(channelId);

        if (channel?.id === channelId) {
          this.channelFacade.unmuteChannel();
        }
      }),
      mapTo(unmuteChannelSucceeded())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userFacade: UserFacade,
    private readonly teamChatService: TeamChatService,
    private readonly streamChatService: StreamChatService,
    private readonly channelsFacade: ChannelsFacade,
    private readonly channelFacade: ChannelFacade,
    private readonly streamChatFacade: StreamChatFacade,
    private readonly securityService: SecurityService,
    private readonly converterService: ConverterService,
    private readonly router: Router,
    private readonly statusFacade: StatusFacade
  ) {}
}
