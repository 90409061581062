import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommunicationService } from '../../services/communication/communication.service';
import { Injectable } from '@angular/core';
import {
  addMembership,
  addMembershipFailed,
  addMembershipSucceeded,
  removeChannel,
  removeChannelSucceeded,
  removeMembership,
  removeMembershipFailed,
  removeMembershipSucceeded,
  setLastMessage,
  updateChannel,
  updateChannelSucceeded,
  updateMembership,
  updateMembershipFailed,
  updateMembershipSucceeded
} from './channels.actions';
import { catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { MemberFacade } from '../team-chat/member/member.facade';
import { SecurityService } from '../../services/security/security.service';
import { CryptoKeyFacade } from '../team-chat/crypto-key/crypto-key.facade';
import { LastMessagePipe } from '../../pipes/last-message.pipe';
import { StatusFacade } from '../status/status.facade';
import { of } from 'rxjs';
import { UserFacade } from '../user/user.facade';
import { ChannelsFacade } from './channels.facade';
import { Channel, Message, MessageType } from '../../app.types';
import { User } from '../../data-access/user';
import { CommunicationEventType } from '@localmed/modento-team-chat-types';

@Injectable({ providedIn: 'root' })
export class ChannelsEffects {
  updateChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateChannel),
      map(({ channel }) => updateChannelSucceeded({ channel }))
    )
  );

  removeChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeChannel),
      map(({ channelId }) => removeChannelSucceeded({ channelId }))
    )
  );

  setLastMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setLastMessage),
      withLatestFrom(
        this.memberFacade.members$,
        this.cryptoKeyFacade.cryptoKey$,
        this.userFacade.user$,
        this.channelsFacade.channels$
      ),
      tap(
        ([
          { channelId, lastMessage, unreadMessagesCount },
          members,
          cryptoKey,
          user,
          channels
        ]) => {
          // do not notify about user own messages
          if (+lastMessage.userId === user.team_member.id) {
            return;
          }

          const storedChannel = channels.find(
            (channel) => channel.id === channelId
          );

          if (!storedChannel) {
            throw new Error('Channel not found');
          }

          if (
            !storedChannel.muteStatus.isMuted &&
            lastMessage.type !== MessageType.SYSTEM &&
            !lastMessage.silent
          ) {
            this.statusFacade.setUnreadMessagesCount(unreadMessagesCount);

            this.communicationService.sendEvent({
              type: CommunicationEventType.NEW_MESSAGE,
              data: {
                channelId,
                user: members.find(
                  (member) => member.id === +lastMessage.userId
                )?.name,
                message: this.lastMessagePipe.transform(
                  this.securityService.decrypt(lastMessage.text, cryptoKey)
                ),
                createdAt: lastMessage.createdAt.toISOString()
              }
            });
          }
        }
      ),
      map(
        ([{ channelId, lastMessage }, members, cryptoKey, user, channels]) => {
          const storedChannel = channels.find(
            (channel) => channel.id === channelId
          );

          if (!storedChannel) {
            throw new Error('Channel not found');
          }

          return updateChannelSucceeded({
            channel: {
              id: channelId,
              isHidden: false,
              lastMessage,
              unreadMessagesCount: this.calculateUnreadMessageCount(
                storedChannel,
                lastMessage,
                user
              ),
              messages: [...storedChannel.messages, lastMessage]
            }
          });
        }
      ),
      catchError((e, retry) => {
        this.channelsFacade.updateChannelFailed();

        return retry;
      })
    )
  );

  updateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMembership),
      map(({ channelId, membership }) =>
        updateMembershipSucceeded({ channelId, membership })
      ),
      catchError((error) => of(updateMembershipFailed(error)))
    )
  );

  addMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMembership),
      map(({ channelId, membership }) =>
        addMembershipSucceeded({ channelId, membership })
      ),
      catchError((error) => of(addMembershipFailed(error)))
    )
  );

  removeMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMembership),
      map(({ channelId, memberId }) =>
        removeMembershipSucceeded({ channelId, memberId })
      ),
      catchError((error) => of(removeMembershipFailed(error)))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly communicationService: CommunicationService,
    private readonly memberFacade: MemberFacade,
    private readonly cryptoKeyFacade: CryptoKeyFacade,
    private readonly securityService: SecurityService,
    private readonly lastMessagePipe: LastMessagePipe,
    private readonly statusFacade: StatusFacade,
    private readonly userFacade: UserFacade,
    private readonly channelsFacade: ChannelsFacade
  ) {}

  private calculateUnreadMessageCount(
    channel: Channel,
    lastMessage: Message,
    user: User
  ): number {
    return +lastMessage.userId !== user.team_member.id && !lastMessage.silent
      ? channel.unreadMessagesCount + 1
      : channel.unreadMessagesCount;
  }
}
