import { createAction, props } from '@ngrx/store';
import { Member } from './member.types';
import { getErrorState } from '../../../common/call-state';

export const getMembers = createAction('[TeamChat/Members] Get members');
export const getMembersSucceeded = createAction(
  '[TeamChat/Members] Get members succeeded',
  props<{ members: Member[] }>()
);
export const getMemberFailed = createAction(
  '[TeamChat/Member] Get members failed',
  getErrorState
);

export const setOnlineStatus = createAction(
  '[TeamChat/Members] Set online status',
  props<{ memberId: number; isOnline: boolean }>()
);
export const setOnlineStatusSucceeded = createAction(
  '[TeamChat/Members] Set online status succeeded',
  props<{ memberId: number; isOnline: boolean }>()
);
export const setOnlineStatusFailed = createAction(
  '[TeamChat/Member] Set online status failed',
  getErrorState
);
