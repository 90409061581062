import { createAction, props } from '@ngrx/store';
import { getErrorState } from '../../../common/call-state';
import { ChannelTypes } from '../../../stream-chat/stream-chat.types';

export const muteChannel = createAction(
  '[ChannelAction] Mute channel',
  props<{ channelId: string; duration: number }>()
);
export const muteChannelSucceeded = createAction(
  '[ChannelAction] Mute channel succeeded'
);
export const muteChannelFailed = createAction(
  '[ChannelAction] Mute channel failed',
  getErrorState
);

export const unmuteChannel = createAction(
  '[ChannelAction] Unmute channel',
  props<{ channelId: string }>()
);
export const unmuteChannelSucceeded = createAction(
  '[ChannelAction] Unmute channel succeeded'
);
export const unmuteChannelFailed = createAction(
  '[ChannelAction] Unmute channel failed',
  getErrorState
);

export const markChannelAsRead = createAction(
  '[ChannelAction] Mark channel as read',
  props<{ channelId: string }>()
);
export const markChannelAsReadSucceeded = createAction(
  '[ChannelAction] Mark channel as read succeeded'
);
export const markChannelAsReadFailed = createAction(
  '[ChannelAction] Mark channel as read failed',
  getErrorState
);

export const hideChannel = createAction(
  '[ChannelAction] Hide channel',
  props<{ channelId: string }>()
);
export const hideChannelSucceeded = createAction(
  '[ChannelAction] Hide channel succeeded'
);
export const hideChannelFailed = createAction(
  '[ChannelAction] Hide channel failed',
  getErrorState
);

export const showChannel = createAction(
  '[ChannelAction] Show channel',
  props<{ channelId: string }>()
);
export const showChannelSucceeded = createAction(
  '[ChannelAction] Show channel succeeded'
);
export const showChannelFailed = createAction(
  '[ChannelAction] Show channel failed',
  getErrorState
);

export const deleteChannel = createAction(
  '[ChannelAction] Delete channel',
  props<{ channelId: string }>()
);
export const deleteChannelSucceeded = createAction(
  '[ChannelAction] Delete channel succeeded'
);
export const deleteChannelFailed = createAction(
  '[ChannelAction] Delete channel failed',
  getErrorState
);

export const leaveChannel = createAction(
  '[ChannelAction] Leave channel',
  props<{ channelId: string }>()
);
export const leaveChannelSucceeded = createAction(
  '[ChannelAction] Leave channel succeeded'
);
export const leaveChannelFailed = createAction(
  '[ChannelAction] Leave channel failed',
  getErrorState
);

export const archiveChannel = createAction(
  '[ChannelAction] Archive channel',
  props<{ channelId: string; channelType: ChannelTypes }>()
);
export const archiveChannelSucceeded = createAction(
  '[ChannelAction] Archive channel succeeded'
);
export const archiveChannelFailed = createAction(
  '[ChannelAction] Archive channel failed',
  getErrorState
);

export const designateModeratorAndLeaveChannel = createAction(
  '[ChannelAction] Designate moderator and leave channel',
  props<{
    channelId: string;
    channelType: ChannelTypes;
    newModeratorMemberIds: number[];
  }>()
);
export const designateModeratorAndLeaveChannelSucceeded = createAction(
  '[ChannelAction] Designate moderator and leave channel succeeded'
);
export const designateModeratorAndLeaveChannelFailed = createAction(
  '[ChannelAction] Designate moderator and leave channel failed',
  getErrorState
);
