import { createAction, props } from '@ngrx/store';
import { Channel, Membership, Message } from '../../app.types';
import { getErrorState } from '../../common/call-state';

export const getChannel = createAction(
  '[Channel] Get channel',
  props<{ channelId: string; subscribeToChanges: boolean }>()
);
export const getChannelSucceeded = createAction(
  '[Channel] Get channel succeeded',
  props<{ channel: Channel }>()
);
export const getChannelFailed = createAction(
  '[Channel] Get channel failed',
  getErrorState
);

export const updateChannel = createAction(
  '[Channel] Update channel',
  props<{ channel: Partial<Channel> }>()
);
export const updateChannelSucceeded = createAction(
  '[Channel] Update channel succeeded',
  props<{ channel: Partial<Channel> }>()
);
export const updateChannelFailed = createAction(
  '[Channel] Update channel failed',
  getErrorState
);

export const clearChannel = createAction(
  '[Channel] Clear channel',
  props<{ channelId: string }>()
);

export const addMessage = createAction(
  '[Channel] Add message',
  props<{ message: Message }>()
);
export const addMessageSucceeded = createAction(
  '[Channel] Add message succeeded',
  props<{ message: Message }>()
);
export const addMessageFailed = createAction(
  '[Channel] Add message failed',
  getErrorState
);

export const sendMessage = createAction(
  '[Channel] Send message',
  props<{ channelId: string; text: string; isSilent: boolean }>()
);
export const sendMessageSucceeded = createAction(
  '[Channel] Send message succeeded'
);
export const sendMessageFailed = createAction(
  '[Channel] Send message failed',
  getErrorState
);

export const loadMoreMessages = createAction(
  '[Channel] Load more messages',
  props<{ channelId: string }>()
);
export const loadMoreMessagesSucceeded = createAction(
  '[Channel] Load more messages succeeded',
  props<{ messages: Message[] }>()
);
export const loadMoreMessagesFailed = createAction(
  '[Channel] Load more messages failed',
  getErrorState
);

export const startTyping = createAction('[Channel] Start typing');
export const startTypingSucceeded = createAction(
  '[Channel] Start typing succeeded'
);
export const startTypingFailed = createAction(
  '[Channel] Start typing failed',
  getErrorState
);

export const stopTyping = createAction('[Channel] Stop typing');
export const stopTypingSucceeded = createAction(
  '[Channel] Stop typing succeeded'
);
export const stopTypingFailed = createAction(
  '[Channel] Stop typing failed',
  getErrorState
);

export const setChannelName = createAction(
  '[Channel] Set channel name',
  props<{ name: string }>()
);
export const setChannelNameSucceeded = createAction(
  '[Channel] Set channel name succeeded',
  props<{ name: string }>()
);
export const setChannelNameFailed = createAction(
  '[Channel] Set channel name failed',
  getErrorState
);

export const addModerator = createAction(
  '[Channel/Members] Add moderator',
  props<{ memberId: number }>()
);
export const addModeratorSucceeded = createAction(
  '[Channel/Members] Add moderator succeeded'
);
export const addModeratorFailed = createAction(
  '[Channel/Members] Add moderator failed',
  getErrorState
);

export const removeModerator = createAction(
  '[Channel/Members] Remove moderator',
  props<{ memberId: number }>()
);
export const removeModeratorSucceeded = createAction(
  '[Channel/Members] Remove moderator succeeded'
);
export const removeModeratorFailed = createAction(
  '[Channel/Members] Remove moderator failed',
  getErrorState
);

export const updateMembership = createAction(
  '[Channel/Members] Update membership',
  props<{ membership: Membership }>()
);
export const updateMembershipSucceeded = createAction(
  '[Channel/Members] Update membership succeeded',
  props<{ membership: Membership }>()
);
export const updateMembershipFailed = createAction(
  '[Channel/Members] Update membership failed',
  getErrorState
);

export const addMembers = createAction(
  '[Channel/Members] Add members',
  props<{ memberIds: number[] }>()
);
export const addMembersSucceeded = createAction(
  '[Channel/Members] Add members succeeded'
);
export const addMembersFailed = createAction(
  '[Channel/Members] Add members failed',
  getErrorState
);

export const addMembership = createAction(
  '[Channel/Members] Add membership',
  props<{ membership: Membership }>()
);
export const addMembershipSucceeded = createAction(
  '[Channel/Members] Add membership succeeded',
  props<{ membership: Membership }>()
);
export const addMembershipFailed = createAction(
  '[Channel/Members] Add membership failed',
  getErrorState
);

export const removeMembers = createAction(
  '[Channel/Members] Remove members',
  props<{ memberIds: number[] }>()
);
export const removeMembersSucceeded = createAction(
  '[Channel/Members] Remove members succeeded'
);
export const removeMembersFailed = createAction(
  '[Channel/Members] Remove members failed',
  getErrorState
);

export const removeMembership = createAction(
  '[Channel/Members] Remove membership',
  props<{ memberId: number }>()
);
export const removeMembershipSucceeded = createAction(
  '[Channel/Members] Remove membership succeeded',
  props<{ memberId: number }>()
);
export const removeMembershipFailed = createAction(
  '[Channel/Members] Remove membership failed',
  getErrorState
);
