import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PracticeStateModule } from './+state/practice/practice-state.module';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientInterceptorsModule } from './common/http-client-interceptors/http-client-interceptors.module';
import { AuthenticationTokenStateModule } from './+state/authentication-token/authentication-token-state.module';
import * as Sentry from '@sentry/angular';
import { PracticeFacade } from './+state/practice/practice.facade';
import { environment } from '../environments/environment';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { VERSION } from '../environments/version';
import { UserStateModule } from './+state/user/user-state.module';
import { UserFacade } from './+state/user/user.facade';
import { LoaderModule } from './shared/loader/loader.module';
import { MatIconRegistry } from '@angular/material/icon';
import { StreamChatModule } from './stream-chat/stream-chat.module';
import { TeamChatStateModule } from './+state/team-chat/team-chat-state.module';
import { StatusStateModule } from './+state/status/status-state.module';
import { ChannelsStateModule } from './+state/channels/channels-state.module';
import { ChannelStateModule } from './+state/channel/channel-state.module';
import { ErrorModule } from './error/error.module';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ChannelActionStateModule } from './shared/channel-action/+state/channel-action-state.module';
import { TeamChatModule } from './team-chat/team-chat.module';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientInterceptorsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (httpBackend: HttpBackend) =>
          createTranslateLoader(httpBackend),
        deps: [HttpBackend],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'TeamChat v2 ' + Math.floor(Math.random() * 10) + 1,
    }), // in order not to duplicate actions from two different instances in the same browser
    LoaderModule,
    StreamChatModule,
    ErrorModule,
    MatSnackBarModule,
    // global states
    PracticeStateModule,
    AuthenticationTokenStateModule,
    UserStateModule,
    TeamChatStateModule,
    ChannelsStateModule,
    ChannelStateModule,
    StatusStateModule,
    ChannelActionStateModule,
    TeamChatModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (practiceFacade: PracticeFacade, userFacade: UserFacade) =>
        initApplication(practiceFacade, userFacade),
      multi: true,
      deps: [PracticeFacade, UserFacade],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({}),
    },
    {
      provide: MatIconRegistry,
      useFactory: (httpBackend: HttpBackend, domSanitizer: DomSanitizer) =>
        createMatIconRegistry(httpBackend, domSanitizer),
      deps: [HttpBackend, DomSanitizer],
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    private readonly translateService: TranslateService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.setI18n();
    this.loadIcons();
  }

  private setI18n(): void {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  private loadIcons(): void {
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/icons.svg'
      )
    );
  }
}

const initApplication = (
  practiceFacade: PracticeFacade,
  userFacade: UserFacade
): (() => Promise<boolean>) => {
  return () =>
    new Promise((resolve, reject) => {
      if (environment.sentry.enabled) {
        Sentry.init({
          enabled: environment.sentry.enabled,
          dsn: environment.sentry.DSN,
          release: `modento-team-chat@${VERSION.version}-${VERSION.hash}`,
          environment: environment.name,
          autoSessionTracking: true,
          integrations: [Sentry.browserTracingIntegration()],

          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: 1.0,
          beforeSend: (event, hint) => {
            return practiceFacade.practiceGuid$
              .pipe(
                take(1),
                withLatestFrom(userFacade.user$),
                map(([practiceGuid, user]) => {
                  if (!event.tags) {
                    event.tags = {};
                  }

                  event.tags.practice_guid = practiceGuid;
                  event.tags.user_id = user.id;

                  if (!event.extra) {
                    event.extra = {};
                  }

                  return event;
                })
              )
              .toPromise();
          },
        });
      }

      resolve(true);
    });
};

const createTranslateLoader = (handler: HttpBackend): TranslateHttpLoader => {
  return new TranslateHttpLoader(new HttpClient(handler));
};

const createMatIconRegistry = (httpBackend, domSanitizer) => {
  return new MatIconRegistry(
    new HttpClient(httpBackend),
    domSanitizer,
    window.document,
    {
      handleError: (error: any) => {},
    }
  );
};
