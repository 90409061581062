import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State } from './status.reducer';

const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectUnreadMessagesCount = createSelector(
  selectState,
  (state) => state.unreadMessagesCount
);

export const selectOnline = createSelector(
  selectState,
  (state: State) => state.online
);

export const selectPreviousUrl = createSelector(
  selectState,
  (state: State) => state.previousUrl
);

export const selectIsMobileApp = createSelector(
  selectState,
  (state) => state.isMobileApp
);
