import { Injectable } from '@angular/core';
import { Channel, Membership, Message } from '../../app.types';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectChannel,
  selectError,
  selectHasMoreMessages,
  selectLoaded,
  selectLoading
} from './channel.selectors';
import {
  addMembers,
  addMembership,
  addMembersSucceeded,
  addMessage,
  addModerator,
  clearChannel,
  getChannel,
  loadMoreMessages,
  removeMembers,
  removeMembership,
  removeModerator,
  sendMessage,
  sendMessageFailed,
  sendMessageSucceeded,
  setChannelName,
  setChannelNameSucceeded,
  startTyping,
  stopTyping,
  updateChannel,
  updateMembership
} from './channel.actions';
import { filter, map, mapTo } from 'rxjs/operators';
import { ErrorState } from '../../common/call-state';
import { Moment } from 'moment';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class ChannelFacade {
  channel$: Observable<Channel> = this.store.pipe(select(selectChannel));
  hasMoreMessages$: Observable<boolean> = this.store.pipe(
    select(selectHasMoreMessages)
  );
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));
  error$: Observable<ErrorState> = this.store.pipe(
    select(selectError),
    filter((error): error is ErrorState => !!error)
  );

  hasChannelNameBeenSet$: Observable<boolean> = this.actions$.pipe(
    ofType(setChannelNameSucceeded),
    map(() => true)
  );

  addMembersSucceeded$: Observable<boolean> = this.actions$.pipe(
    ofType(addMembersSucceeded),
    mapTo(true)
  );

  sendMessageSucceeded$: Observable<boolean> = this.actions$.pipe(
    ofType(sendMessageSucceeded),
    map(() => true)
  );

  sendMessageFailed$: Observable<boolean> = this.actions$.pipe(
    ofType(sendMessageFailed),
    map(() => true)
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  getChannel(channelId: string, subscribeToChanges: boolean = true): void {
    this.store.dispatch(getChannel({ channelId, subscribeToChanges }));
  }

  clearChannel(channelId: string): void {
    this.store.dispatch(clearChannel({ channelId }));
  }

  addMessage(message: Message): void {
    this.store.dispatch(addMessage({ message }));
  }

  sendMessage(
    channelId: string,
    text: string,
    isSilent: boolean = false
  ): void {
    this.store.dispatch(sendMessage({ channelId, text, isSilent }));
  }

  updateChannel(channel: Partial<Channel>): void {
    this.store.dispatch(updateChannel({ channel }));
  }

  unmuteChannel(): void {
    this.updateChannel({
      muteStatus: {
        isMuted: false,
        expiresAt: null
      }
    });
  }

  muteChannel(expiresAt: Moment): void {
    this.updateChannel({
      muteStatus: {
        isMuted: true,
        expiresAt
      }
    });
  }

  showChannel(): void {
    this.updateChannel({
      isHidden: false
    });
  }

  hideChannel(): void {
    this.updateChannel({
      isHidden: true
    });
  }

  loadMoreMessages(channelId: string): void {
    this.store.dispatch(loadMoreMessages({ channelId }));
  }

  startTyping(): void {
    this.store.dispatch(startTyping());
  }

  stopTyping(): void {
    this.store.dispatch(stopTyping());
  }

  markChannelAsRead(): void {
    this.updateChannel({
      unreadMessagesCount: 0
    });
  }

  setChannelName(name: string): void {
    this.store.dispatch(setChannelName({ name }));
  }

  addModerator(memberId: number): void {
    this.store.dispatch(addModerator({ memberId }));
  }

  removeModerator(memberId: number): void {
    this.store.dispatch(removeModerator({ memberId }));
  }

  updateMembership(membership: Membership): void {
    this.store.dispatch(updateMembership({ membership }));
  }

  addMembers(memberIds: number[]): void {
    this.store.dispatch(addMembers({ memberIds }));
  }

  addMembership(membership: Membership): void {
    this.store.dispatch(addMembership({ membership }));
  }

  removeMembers(memberIds: number[]): void {
    this.store.dispatch(removeMembers({ memberIds }));
  }

  removeMembership(memberId: number): void {
    this.store.dispatch(removeMembership({ memberId }));
  }
}
