import { Action, createReducer, on } from '@ngrx/store';
import {
  addMembers,
  addMembersFailed,
  addMembership,
  addMembersSucceeded,
  addMessageSucceeded,
  addModerator,
  addModeratorFailed,
  addModeratorSucceeded,
  clearChannel,
  getChannel,
  getChannelFailed,
  getChannelSucceeded,
  loadMoreMessagesSucceeded,
  removeMembers,
  removeMembersFailed,
  removeMembershipSucceeded,
  removeMembersSucceeded,
  removeModerator,
  removeModeratorFailed,
  removeModeratorSucceeded,
  sendMessageFailed,
  setChannelName,
  setChannelNameFailed,
  setChannelNameSucceeded,
  updateChannelSucceeded,
  updateMembershipFailed,
  updateMembershipSucceeded
} from './channel.actions';
import { Channel, MemberRole } from '../../app.types';
import { CallState, LoadingState } from '../../common/call-state';
import { environment } from '../../../environments/environment';

export const FEATURE_KEY = 'channel';

export interface State {
  channel: Channel;
  callState: CallState;
  hasMoreMessages: boolean;
  loadingMessages: boolean;
}

export const initialState: State = {
  channel: null,
  callState: LoadingState.INIT,
  hasMoreMessages: null,
  loadingMessages: false
};

const channelReducer = createReducer(
  initialState,
  on(
    getChannel,
    setChannelName,
    addModerator,
    removeModerator,
    addMembers,
    removeMembers,
    (state) => ({
      ...state,
      callState: LoadingState.LOADING
    })
  ),
  on(getChannelSucceeded, (state, { channel }) => ({
    ...state,
    channel,
    callState: LoadingState.LOADED
  })),
  on(clearChannel, (state) => ({
    ...state,
    channel: undefined,
    callState: LoadingState.INIT
  })),
  on(updateChannelSucceeded, (state, { channel }) => ({
    ...state,
    channel: {
      ...state.channel,
      ...channel
    }
  })),
  on(addMessageSucceeded, (state, { message }) => ({
    ...state,
    channel: {
      ...state.channel,
      messages: [...state.channel.messages, message]
    }
  })),
  on(loadMoreMessagesSucceeded, (state, { messages }) => ({
    ...state,
    channel: {
      ...state.channel,
      messages: [...messages, ...state.channel.messages]
    },
    hasMoreMessages: messages.length === environment.channel.messagesPerPage
  })),
  on(
    getChannelFailed,
    setChannelNameFailed,
    addModeratorFailed,
    removeModeratorFailed,
    updateMembershipFailed,
    addMembersFailed,
    removeMembersFailed,
    sendMessageFailed,
    (state, error) => ({
      ...state,
      callState: error
    })
  ),
  on(setChannelNameSucceeded, (state, { name }) => ({
    ...state,
    callState: LoadingState.LOADED,
    channel: {
      ...state.channel,
      name
    }
  })),
  on(
    addModeratorSucceeded,
    removeModeratorSucceeded,
    addMembersSucceeded,
    removeMembersSucceeded,
    (state) => ({
      ...state,
      callState: LoadingState.LOADED
    })
  ),
  on(updateMembershipSucceeded, (state, { membership }) => {
    const currentMembership = { ...state.channel.membership };
    const memberRoles = { ...state.channel.memberRoles };
    const moderatorIds = [...state.channel.moderatorIds];

    if (currentMembership.memberId === membership.memberId) {
      currentMembership.role = membership.role;
    }

    if (
      membership.role === MemberRole.MODERATOR &&
      !moderatorIds.includes(membership.memberId)
    ) {
      moderatorIds.push(membership.memberId);
    }

    if (
      membership.role !== MemberRole.MODERATOR &&
      moderatorIds.includes(membership.memberId)
    ) {
      moderatorIds.splice(moderatorIds.indexOf(membership.memberId), 1);
    }

    memberRoles[membership.memberId] = membership.role;

    return {
      ...state,
      channel: {
        ...state.channel,
        membership: currentMembership,
        moderatorIds,
        memberRoles
      }
    };
  }),
  on(addMembership, (state, { membership }) => {
    const memberIds = [...state.channel.memberIds];
    const memberRoles = { ...state.channel.memberRoles };

    memberIds.push(membership.memberId);
    memberRoles[membership.memberId] = membership.role;

    return {
      ...state,
      channel: {
        ...state.channel,
        memberIds,
        memberRoles
      }
    };
  }),
  on(removeMembershipSucceeded, (state, { memberId }) => {
    let currentMembership = { ...state.channel.membership };
    const memberIds = [...state.channel.memberIds];
    const memberRoles = { ...state.channel.memberRoles };
    const moderatorIds = [...state.channel.moderatorIds];

    if (currentMembership.memberId === memberId) {
      currentMembership = null;
    }

    if (memberRoles[memberId] === MemberRole.MODERATOR) {
      moderatorIds.splice(moderatorIds.indexOf(memberId), 1);
    }

    memberIds.splice(memberIds.indexOf(memberId), 1);
    delete memberRoles[memberId];

    return {
      ...state,
      channel: {
        ...state.channel,
        membership: currentMembership,
        memberIds,
        memberRoles,
        moderatorIds
      }
    };
  })
);

export const reducer = (state: State, action: Action): State => {
  return channelReducer(state, action);
};
