import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private lastMessage;
  private lastMessageTime: moment.Moment;

  private readonly notificationDuration: number = 3000;

  constructor(private readonly matSnackbar: MatSnackBar) {}

  show(message: string): void {
    const now = moment();

    if (
      this.lastMessage === message &&
      this.lastMessageTime &&
      now.diff(this.lastMessageTime) < this.notificationDuration
    ) {
      return;
    }

    this.lastMessage = message;
    this.lastMessageTime = now;

    this.matSnackbar.open(message, undefined, {
      duration: this.notificationDuration,
    });
  }
}
