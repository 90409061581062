import { createAction, props } from '@ngrx/store';
import { getErrorState } from '../../common/call-state';
import { Practice } from '../../data-access/practice';

export const loadPractice = createAction(
  '[Practice] Load practice',
  props<{ practiceGuid: string }>()
);

export const loadPracticeSucceeded = createAction(
  '[Practice] Load practice succeeded',
  props<{ practice: Practice }>()
);

export const loadPracticeFailed = createAction(
  '[Practice] Load practice failed',
  getErrorState
);
