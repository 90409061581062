import { User } from '../../data-access/user';
import { Action, createReducer, on } from '@ngrx/store';
import { CallState, LoadingState } from '../../common/call-state';
import { loadUser, loadUserFailed, loadUserSucceeded } from './user.actions';

export const FEATURE_KEY = 'user';

export interface State {
  callState: CallState;
  user: User | null;
}

export const initialState: State = {
  callState: LoadingState.INIT,
  user: null
};

const userReducer = createReducer(
  initialState,
  on(loadUser, (state) => ({
    ...state,
    callState: LoadingState.LOADING
  })),
  on(loadUserSucceeded, (state, { user }) => ({
    ...state,
    user,
    callState: LoadingState.LOADED
  })),
  on(loadUserFailed, (state, error) => ({
    ...state,
    callState: error
  }))
);

export const reducer = (state: State, action: Action): State => {
  return userReducer(state, action);
};
