import { Moment } from 'moment';
import { APIResponse, Channel, UserResponse } from 'stream-chat';

export enum ChannelTypes {
  MESSAGING = 'messaging',
  TEAM = 'team'
}

export interface MutedChannel {
  id: string;
  expiresAt: Moment;
}

export type StreamChatChannel = Channel;

export type QueryUsersResponse = APIResponse & { users: UserResponse[] };
