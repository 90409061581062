import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { UserFacade } from '../../+state/user/user.facade';
import { Observable } from 'rxjs';
import { User } from '../../data-access/user';

@Component({
  selector: 'app-oh-no',
  templateUrl: './oh-no.component.html',
  styleUrls: ['./oh-no.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OhNoComponent {
  @Output() refreshClicked: EventEmitter<void> = new EventEmitter<void>();

  user$: Observable<User> = this.userFacade.user$;

  constructor(private readonly userFacade: UserFacade) {}

  refresh(): void {
    this.refreshClicked.emit();
  }
}
