import { createAction, props } from '@ngrx/store';
import { getErrorState } from '../../../common/call-state';
import { MutedChannel } from '../../stream-chat.types';

export const initializeStreamChat = createAction(
  '[StreamChat/Initialize] Initialize Stream Chat'
);
export const initializeStreamChatSucceeded = createAction(
  '[StreamChat/Initialize] Initialize Stream Chat succeeded'
);
export const initializeStreamChatFailed = createAction(
  '[StreamChat/Initialize] Initialize Stream Chat failed',
  getErrorState
);

export const updateMuteStatus = createAction(
  '[StreamChat/MuteStatus] Update mute status',
  props<{ mutedChannels: MutedChannel[] }>()
);
export const updateMuteStatusSucceeded = createAction(
  '[StreamChat/MuteStatus] Update mute status succeeded'
);
export const updateMuteStatusFailed = createAction(
  '[StreamChat/MuteStatus] Update mute status failed',
  getErrorState
);

export const unmuteChannel = createAction(
  '[StreamChat/Unmute] Unmute channel',
  props<{ channelId: string }>()
);
export const unmuteChannelSucceeded = createAction(
  '[StreamChat/Unmute] Unmute channel succeeded'
);
export const unmuteChannelFailed = createAction(
  '[StreamChat/Unmute] Unmute channel failed',
  getErrorState
);
