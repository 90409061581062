export const environment = {
  name: 'dev',
  production: false,
  apiUrl: 'https://dev.modento.io/api/v1',
  sentry: {
    enabled: true,
    DSN:
      'https://5cb33a4c2f20476c8fa9e5ff8f38b600@o109559.ingest.sentry.io/5661230'
  },
  teamChat: {
    key: 'sy8w5hh77s4v',
    timeout: 6000
  },
  channel: {
    messagesPerPage: 20
  },
  development: {
    allowRemovingChannels: false
  },
  giphy: {
    apiKey: 'OIXHXHPaLkKKmkQr7ANC4YplU6T5QSCQ'
  },
  postMessage: {
    allowedModentoGoUrls: ['https://dev-connect.modento.io'],
    allowedDashboardUrls: ['https://dev-office.modento.io']
  }
};
