import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamChatComponent } from './team-chat.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [TeamChatComponent],
  imports: [CommonModule, RouterModule]
})
export class TeamChatModule {}
