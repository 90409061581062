import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addMembers,
  addMembersFailed,
  addMembersSucceeded,
  addModerator,
  addModeratorFailed,
  addModeratorSucceeded,
  clearChannel,
  getChannel,
  getChannelFailed,
  getChannelSucceeded,
  loadMoreMessages,
  loadMoreMessagesFailed,
  loadMoreMessagesSucceeded,
  removeMembers,
  removeMembersFailed,
  removeMembersSucceeded,
  removeModerator,
  removeModeratorFailed,
  removeModeratorSucceeded,
  sendMessage,
  sendMessageFailed,
  sendMessageSucceeded,
  setChannelName,
  setChannelNameFailed,
  setChannelNameSucceeded,
  startTyping,
  startTypingFailed,
  startTypingSucceeded,
  stopTyping,
  stopTypingFailed,
  stopTypingSucceeded
} from '../../../+state/channel/channel.actions';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { combineLatest, of, zip } from 'rxjs';
import { ChannelFacade } from '../../../+state/channel/channel.facade';
import { SecurityService } from '../../../services/security/security.service';
import { ConverterService } from '../../converter.service';
import { CryptoKeyFacade } from '../../../+state/team-chat/crypto-key/crypto-key.facade';
import { StreamChatService } from '../../stream-chat.service';
import { TeamChatService } from '../../../data-access/team-chat/team-chat.service';
import { MemberFacade } from '../../../+state/team-chat/member/member.facade';
import { Member } from '../../../+state/team-chat/member/member.types';
import { TranslateService } from '@ngx-translate/core';
import {
  designateModeratorAndLeaveChannel,
  designateModeratorAndLeaveChannelFailed,
  designateModeratorAndLeaveChannelSucceeded,
  leaveChannel,
  leaveChannelFailed,
  leaveChannelSucceeded
} from '../../../shared/channel-action/+state/channel-action.actions';
import { UserFacade } from '../../../+state/user/user.facade';
import { ChannelsFacade } from '../../../+state/channels/channels.facade';

@Injectable()
export class StreamChatChannelEffects {
  getChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChannel),
      switchMap((action) =>
        combineLatest([
          this.streamChatService.fetchChannels({
            id: action.channelId
          }),
          this.streamChatService.fetchChannels({
            id: action.channelId,
            hidden: true
          }),
          of(action.subscribeToChanges)
        ]).pipe(
          tap(
            ([
              visibleStreamChatChannels,
              hiddenStreamChatChannels,
              subscribeToChanges
            ]) => {
              const streamChatChannel = visibleStreamChatChannels.length
                ? visibleStreamChatChannels[0]
                : hiddenStreamChatChannels[0];

              this.streamChatService.addChannel(streamChatChannel);
              if (subscribeToChanges) {
                this.streamChatService.subscribeToChannelEvents(
                  streamChatChannel.id
                );
              }
            }
          ),
          map(([visibleStreamChatChannels, hiddenStreamChatChannels]) => {
            const streamChatChannel = visibleStreamChatChannels.length
              ? visibleStreamChatChannels[0]
              : hiddenStreamChatChannels[0];

            return this.converterService.convertStreamChatChannelToChannel(
              streamChatChannel,
              !!hiddenStreamChatChannels.length
            );
          }),
          map((channel) => getChannelSucceeded({ channel })),
          catchError((error) => of(getChannelFailed(error)))
        )
      )
    )
  );

  unsubscribeFromChannelEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearChannel),
        tap((action) =>
          this.streamChatService.unsubscribeFromChannelEvents(action.channelId)
        )
      ),
    { dispatch: false }
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessage),
      withLatestFrom(this.cryptoKeyFacade.cryptoKey$),
      switchMap(([action, cryptoKey]) =>
        this.streamChatService
          .sendMessage(
            action.channelId,
            this.securityService.encrypt(action.text, cryptoKey),
            action.isSilent
          )
          .pipe(
            map(() => sendMessageSucceeded()),
            catchError((error) => of(sendMessageFailed(error)))
          )
      )
    )
  );

  loadMoreMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreMessages),
      switchMap((action) =>
        this.streamChatService.loadMoreMessages(action.channelId).pipe(
          map(({ messages }) =>
            messages.map((message) =>
              this.converterService.convertStreamChatMessageToMessage(message)
            )
          ),
          map((newMessages) =>
            loadMoreMessagesSucceeded({ messages: newMessages })
          ),
          catchError((error) => of(loadMoreMessagesFailed(error)))
        )
      )
    )
  );

  startTyping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startTyping),
      withLatestFrom(this.channelFacade.channel$),
      filter(([action, channel]) => !!channel),
      switchMap(([action, channel]) =>
        this.streamChatService.startTyping(channel.id).pipe(
          map(() => startTypingSucceeded()),
          catchError((error) => of(startTypingFailed(error)))
        )
      )
    )
  );

  stopTyping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopTyping),
      withLatestFrom(this.channelFacade.channel$),
      filter(([action, channel]) => !!channel),
      switchMap(([action, channel]) =>
        this.streamChatService.stopTyping(channel.id).pipe(
          map(() => stopTypingSucceeded()),
          catchError((error) => of(stopTypingFailed(error)))
        )
      )
    )
  );

  setChannelName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setChannelName),
      withLatestFrom(this.channelFacade.channel$),
      switchMap(([{ name }, channel]) =>
        this.streamChatService
          .updateChannel(channel.id, {
            set: { name }
          })
          .pipe(
            map(() => setChannelNameSucceeded({ name })),
            catchError((error) => of(setChannelNameFailed(error)))
          )
      )
    )
  );

  addModerator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addModerator),
      withLatestFrom(this.channelFacade.channel$),
      switchMap(([action, channel]) =>
        this.teamChatService
          .addModerator(channel.id, channel.type, action.memberId.toString())
          .pipe(
            map(() => addModeratorSucceeded()),
            catchError((error) => of(addModeratorFailed(error)))
          )
      )
    )
  );

  removeModerator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeModerator),
      withLatestFrom(this.channelFacade.channel$),
      switchMap(([action, channel]) =>
        this.teamChatService
          .removeModerator(channel.id, channel.type, action.memberId.toString())
          .pipe(
            map(() => removeModeratorSucceeded()),
            catchError((error) => of(removeModeratorFailed(error)))
          )
      )
    )
  );

  addMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMembers),
      withLatestFrom(
        this.channelFacade.channel$,
        this.memberFacade.members$,
        this.cryptoKeyFacade.cryptoKey$
      ),
      switchMap(([{ memberIds }, channel, members, cryptoKey]) =>
        this.streamChatService
          .addMembers(
            channel.id,
            memberIds.map((memberId) => memberId.toString()),
            this.securityService.encrypt(
              this.translateService.instant('stream-chat.add-members-message', {
                members: members
                  .filter((member: Member) => memberIds.includes(member.id))
                  .map((member: Member) => member.name)
                  .join(', '),
                count: memberIds.length
              }),
              cryptoKey
            )
          )
          .pipe(
            map(() => addMembersSucceeded()),
            catchError((error) => of(addMembersFailed(error)))
          )
      )
    )
  );

  removeMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMembers),
      withLatestFrom(
        this.channelFacade.channel$,
        this.memberFacade.members$,
        this.cryptoKeyFacade.cryptoKey$
      ),
      switchMap(([{ memberIds }, channel, members, cryptoKey]) =>
        this.streamChatService
          .removeMembers(
            channel.id,
            memberIds.map((memberId) => memberId.toString()),
            this.securityService.encrypt(
              this.translateService.instant(
                'stream-chat.remove-members-message',
                {
                  members: members
                    .filter((member: Member) => memberIds.includes(member.id))
                    .map((member: Member) => member.name)
                    .join(', '),
                  count: memberIds.length
                }
              ),
              cryptoKey
            )
          )
          .pipe(
            map(() => removeMembersSucceeded()),
            catchError((error) => of(removeMembersFailed(error)))
          )
      )
    )
  );

  leaveChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leaveChannel),
      withLatestFrom(this.userFacade.user$, this.cryptoKeyFacade.cryptoKey$),
      switchMap(([{ channelId }, user, cryptoKey]) =>
        this.streamChatService
          .removeMembers(
            channelId,
            [user.team_member.id.toString()],
            this.securityService.encrypt(
              this.translateService.instant(
                'stream-chat.leave-channel-message',
                {
                  name: user.team_member.name
                }
              ),
              cryptoKey
            )
          )
          .pipe(
            map(() => leaveChannelSucceeded()),
            catchError((error) => of(leaveChannelFailed(error)))
          )
      )
    )
  );

  designateModeratorAndLeaveChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(designateModeratorAndLeaveChannel),
      withLatestFrom(this.userFacade.user$, this.cryptoKeyFacade.cryptoKey$),
      switchMap(
        ([
          { channelId, channelType, newModeratorMemberIds },
          user,
          cryptoKey
        ]) =>
          zip(
            ...newModeratorMemberIds.map((memberId) =>
              this.teamChatService.addModerator(
                channelId,
                channelType,
                memberId.toString()
              )
            )
          ).pipe(
            switchMap(() =>
              this.streamChatService
                .removeMembers(
                  channelId,
                  [user.team_member.id.toString()],
                  this.securityService.encrypt(
                    this.translateService.instant(
                      'stream-chat.leave-channel-message',
                      {
                        name: user.team_member.name
                      }
                    ),
                    cryptoKey
                  )
                )
                .pipe(map(() => designateModeratorAndLeaveChannelSucceeded()))
            ),
            catchError((error) =>
              of(designateModeratorAndLeaveChannelFailed(error))
            )
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly streamChatService: StreamChatService,
    private readonly channelFacade: ChannelFacade,
    private readonly securityService: SecurityService,
    private readonly converterService: ConverterService,
    private readonly cryptoKeyFacade: CryptoKeyFacade,
    private readonly teamChatService: TeamChatService,
    private readonly memberFacade: MemberFacade,
    private readonly translateService: TranslateService,
    private readonly userFacade: UserFacade,
    private readonly channelsFacade: ChannelsFacade
  ) {}
}
