import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State } from './crypto-key.reducer';
import { LoadingState } from '../../../common/call-state';
import * as fromTeamChat from '../team-chat.state';

const teamChatSelectState = createFeatureSelector<fromTeamChat.TeamChatState>(
  fromTeamChat.FEATURE_KEY
);
const featureSelector = createSelector(
  teamChatSelectState,
  (state) => state[FEATURE_KEY]
);

export const selectCryptoKey = createSelector(
  featureSelector,
  (state: State) => state.cryptoKey
);

export const selectLoading = createSelector(
  featureSelector,
  (state: State) => state.callState === LoadingState.LOADING
);

export const selectLoaded = createSelector(
  featureSelector,
  (state: State) => state.callState === LoadingState.LOADED
);

export const selectError = createSelector(featureSelector, (state: State) => {
  return typeof state.callState !== 'object' ? null : state.callState;
});
