import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './channels.reducer';
import {
  addChannel,
  addMembership,
  getChannels,
  muteAllChannels,
  removeChannel,
  removeMembership,
  setLastMessage,
  showHiddenChannels,
  showVisibleChannels,
  unmuteAllChannels,
  updateChannel,
  updateChannelFailed,
  updateMembership
} from './channels.actions';
import { Observable } from 'rxjs';
import {
  selectAll,
  selectAreHiddenChannelsVisible,
  selectError,
  selectLoaded,
  selectLoading
} from './channels.selectors';
import { filter, map } from 'rxjs/operators';
import { Channel, Membership, Message } from '../../app.types';
import { ErrorState } from '../../common/call-state';
import { Moment } from 'moment';
import { UpdateChannel } from './channels.types';

@Injectable({
  providedIn: 'root'
})
export class ChannelsFacade {
  channels$: Observable<Channel[]> = this.store.pipe(select(selectAll));
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));
  error$: Observable<ErrorState> = this.store.pipe(
    select(selectError),
    filter((error): error is ErrorState => !!error)
  );
  areHiddenChannelsVisible$: Observable<boolean> = this.store.pipe(
    select(selectAreHiddenChannelsVisible)
  );
  areAllMuted$: Observable<boolean> = this.channels$.pipe(
    map(
      (channels: Channel[]) =>
        !channels.find((channel: Channel) => !channel.muteStatus.isMuted)
    )
  );

  constructor(private readonly store: Store<State>) {}

  getChannels(): void {
    this.store.dispatch(getChannels());
  }

  addChannel(channelId: string): void {
    this.store.dispatch(addChannel({ channelId }));
  }

  removeChannel(channelId: string): void {
    this.store.dispatch(removeChannel({ channelId }));
  }

  updateChannel(channel: UpdateChannel): void {
    this.store.dispatch(updateChannel({ channel }));
  }

  showHiddenChannels(): void {
    this.store.dispatch(showHiddenChannels());
  }

  showVisibleChannels(): void {
    this.store.dispatch(showVisibleChannels());
  }

  markChannelAsRead(channelId: string): void {
    this.updateChannel({
      id: channelId,
      unreadMessagesCount: 0
    });
  }

  muteAllChannels(duration: number): void {
    this.store.dispatch(muteAllChannels({ duration }));
  }

  unmuteAllChannels(): void {
    this.store.dispatch(unmuteAllChannels());
  }

  muteChannel(channelId: string, expiresAt: Moment): void {
    this.updateChannel({
      id: channelId,
      muteStatus: {
        isMuted: true,
        expiresAt
      }
    });
  }

  unmuteChannel(channelId: string): void {
    this.updateChannel({
      id: channelId,
      muteStatus: {
        isMuted: false,
        expiresAt: null
      }
    });
  }

  hideChannel(channelId: string): void {
    this.updateChannel({
      id: channelId,
      isHidden: true
    });
  }

  showChannel(channelId: string): void {
    this.updateChannel({
      id: channelId,
      isHidden: false
    });
  }

  setLastMessage(
    channelId: string,
    lastMessage: Message,
    unreadMessagesCount: number
  ): void {
    this.store.dispatch(
      setLastMessage({
        channelId,
        lastMessage,
        unreadMessagesCount
      })
    );
  }

  updateChannelFailed(): void {
    this.store.dispatch(updateChannelFailed());
  }

  typingStart(channelId: string): void {
    this.updateChannel({
      id: channelId,
      isTyping: true
    });
  }

  typingStop(channelId: string): void {
    this.updateChannel({
      id: channelId,
      isTyping: false
    });
  }

  updateMembership(channelId: string, membership: Membership): void {
    this.store.dispatch(updateMembership({ channelId, membership }));
  }

  addMembership(channelId: string, membership: Membership): void {
    this.store.dispatch(addMembership({ channelId, membership }));
  }

  removeMembership(channelId: string, memberId: number): void {
    this.store.dispatch(removeMembership({ channelId, memberId }));
  }
}
