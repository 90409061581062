import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TeamChatMember } from './team-chat.types';
import { ChannelTypes } from '../../stream-chat/stream-chat.types';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamChatService {
  static GET_TEAM_MEMBERS_URL = 'staff/team-chat/team-members';
  static SYNC_CURRENT_USER_URL = 'staff/team-chat/sync-current-user';
  static GET_CRYPTO_KEY_URL = 'staff/team-chat/get-crypto-key';

  constructor(private readonly httpClient: HttpClient) {}

  getTeamMembers(): Observable<TeamChatMember[]> {
    return this.httpClient.get<TeamChatMember[]>(
      TeamChatService.GET_TEAM_MEMBERS_URL
    );
  }

  syncCurrentUser(): Observable<string> {
    return this.httpClient.get<string>(TeamChatService.SYNC_CURRENT_USER_URL);
  }

  getCryptoKey(): Observable<string> {
    return this.httpClient.get<string>(TeamChatService.GET_CRYPTO_KEY_URL);
  }

  addModerator(
    channelId: string,
    channelType: ChannelTypes,
    userId: string
  ): Observable<boolean> {
    return this.httpClient
      .post(`staff/team-chat/channels/${channelId}/moderators`, {
        channel_type: channelType,
        user_id: userId
      })
      .pipe(mapTo(true));
  }

  removeModerator(
    channelId: string,
    channelType: ChannelTypes,
    userId: string
  ): Observable<boolean> {
    return this.httpClient
      .delete(`staff/team-chat/channels/${channelId}/moderators`, {
        params: {
          channel_type: channelType,
          user_id: userId
        }
      })
      .pipe(mapTo(true));
  }

  archiveChannel(
    channelId: string,
    channelType: ChannelTypes,
    userId: string
  ): Observable<boolean> {
    return this.httpClient
      .patch<boolean>(`staff/team-chat/channels/${channelId}/archive`, {
        channel_type: channelType,
        archive_by: userId
      })
      .pipe(mapTo(true));
  }
}
