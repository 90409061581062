import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ErrorPageRedirectReason } from '../../app.types';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly connectionFailedError: string = this.translateService.instant(
    'http-client-interceptors.error-interceptor.connection-failed'
  );
  private readonly unknownErrorMessage: string = this.translateService.instant(
    'http-client-interceptors.error-interceptor.unknown'
  );

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = this.getErrorMessage(error);

        this.router.navigate(['error'], {
          state: { reason: ErrorPageRedirectReason.HTTP_REQUEST_ERROR }
        });

        error.error.message = errorMessage;

        return throwError(error);
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    if (!navigator.onLine) {
      return this.connectionFailedError;
    }

    if (error.error && error.error.errors) {
      const serverErrors = Object.keys(error.error.errors).map(
        (x) => error.error.errors[x]
      );
      return serverErrors[0].join('<br>');
    } else if (
      error.error &&
      error.error.message &&
      error.error.message !== ''
    ) {
      return error.error.message;
    } else {
      return this.unknownErrorMessage;
    }
  }
}
