import { Injectable } from '@angular/core';
import {
  ChannelMembership,
  FormatMessageResponse,
  MessageResponse,
  UserResponse
} from 'stream-chat';
import { Member } from '../+state/team-chat/member/member.types';
import * as moment from 'moment';
import {
  Channel,
  MemberRole,
  Membership,
  Message,
  MessageType
} from '../app.types';
import { ChannelTypes, StreamChatChannel } from './stream-chat.types';
import { TeamChatMember } from '../data-access/team-chat/team-chat.types';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {
  constructor() {}

  convertStreamChatChannelToChannel(
    streamChatChannel: StreamChatChannel,
    hidden: boolean = false
  ): Channel {
    const otherMembers = Object.values(streamChatChannel.state.members).filter(
      (member) => member.user_id !== streamChatChannel.state.membership.user.id
    );

    const isOnline = !!otherMembers.find(
      (member: ChannelMembership) => member.user.online
    );

    return {
      id: streamChatChannel.id,
      type: streamChatChannel.type as ChannelTypes,
      name: streamChatChannel.data.name ?? '',
      createdAt: moment(streamChatChannel.data.created_at),
      creatorId: +(streamChatChannel.data.created_by as UserResponse).id,
      membership: {
        memberId: +streamChatChannel.state.membership.user.id,
        role: this.getMemberRole(
          streamChatChannel.state.members[
            streamChatChannel.state.membership.user.id
          ]
        )
      },
      memberIds: Object.keys(streamChatChannel.state.members).map(
        (memberId) => +memberId
      ),
      memberRoles: Object.assign(
        {},
        ...Object.keys(streamChatChannel.state.members).map((memberId) => ({
          [memberId]: this.getMemberRole(
            streamChatChannel.state.members[memberId]
          )
        }))
      ),
      moderatorIds: Object.values(streamChatChannel.state.members)
        .filter((member) => this.getMemberRole(member) === MemberRole.MODERATOR)
        .map((member) => +member.user_id),
      unreadMessagesCount: streamChatChannel.state.unreadCount,
      isGroup:
        streamChatChannel.type === ChannelTypes.TEAM ||
        // @ts-ignore I have to ignore this line because customData object has unknown type and adding typing only for this single use case will be really painful
        !!streamChatChannel.data.customData?.isGroup,
      isOnline,
      isHidden: hidden,
      isArchived: !!streamChatChannel.data.disabled,
      isTyping: false,
      muteStatus: {
        isMuted: streamChatChannel.muteStatus().muted,
        expiresAt: streamChatChannel.muteStatus().expiresAt
          ? moment(streamChatChannel.muteStatus().expiresAt)
          : null
      },
      messages: streamChatChannel.state.messages.map((message) =>
        this.convertStreamChatMessageToMessage(message)
      ),
      lastMessage: streamChatChannel.lastMessage()
        ? this.convertStreamChatMessageToMessage(
            streamChatChannel.lastMessage()
          )
        : null
    };
  }

  convertStreamChatMessageToMessage(
    streamChatMessage: MessageResponse | FormatMessageResponse
  ): Message {
    return {
      id: streamChatMessage.id,
      type: streamChatMessage.type as MessageType,
      userId: streamChatMessage.user.id,
      createdAt: moment(streamChatMessage.created_at),
      text: streamChatMessage.text,
      silent: streamChatMessage.silent
    };
  }

  convertTeamMemberAndStreamChatUserToMember(
    teamMember: TeamChatMember,
    streamChatUser: UserResponse
  ): Member {
    return {
      id: teamMember.id,
      chatConnected: teamMember.chat_connected,
      createdAt: moment(teamMember.created_at),
      name: teamMember.name,
      practiceId: teamMember.practice_id,
      practiceName: teamMember.practice_name,
      isOnline: streamChatUser ? streamChatUser.online : false,
      updatedAt: moment(teamMember.updated_at),
      image: teamMember.image
    };
  }

  convertStreamChatMembershipToMembership(
    streamChatUser: ChannelMembership
  ): Membership {
    return {
      memberId: +streamChatUser.user.id,
      role: this.getMemberRole(streamChatUser)
    };
  }

  private getMemberRole(member: ChannelMembership): MemberRole {
    if (member.is_moderator) {
      return MemberRole.MODERATOR;
    }

    if (member.role === MemberRole.OWNER) {
      // if owner is not a moderator
      return MemberRole.USER;
    }

    return member.role as MemberRole;
  }
}
