import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addMembership,
  addMembershipSucceeded,
  addMessage,
  addMessageFailed,
  addMessageSucceeded,
  removeMembersFailed,
  removeMembership,
  removeMembershipSucceeded,
  updateChannel,
  updateChannelFailed,
  updateChannelSucceeded,
  updateMembership,
  updateMembershipFailed,
  updateMembershipSucceeded
} from './channel.actions';
import { catchError, filter, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ChannelFacade } from './channel.facade';

@Injectable()
export class ChannelEffects {
  updateChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateChannel),
      withLatestFrom(this.channelFacade.channel$),
      filter(([action, channel]) => !!channel),
      map(([{ channel }]) => updateChannelSucceeded({ channel })),
      catchError((error) => of(updateChannelFailed(error)))
    )
  );

  addMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMessage),
      withLatestFrom(this.channelFacade.channel$),
      filter(([{ message }, channel]) => !!channel),
      map(([{ message }]) => addMessageSucceeded({ message })),
      catchError((error) => of(addMessageFailed(error)))
    )
  );

  updateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMembership),
      map(({ membership }) => updateMembershipSucceeded({ membership })),
      catchError((error) => of(updateMembershipFailed(error)))
    )
  );

  addMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMembership),
      map(({ membership }) => addMembershipSucceeded({ membership })),
      catchError((error) => of(addMembershipSucceeded(error)))
    )
  );

  removeMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMembership),
      map(({ memberId }) => removeMembershipSucceeded({ memberId })),
      catchError((error) => of(removeMembersFailed(error)))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly channelFacade: ChannelFacade
  ) {}
}
