import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'messageDate'
})
export class MessageDatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private readonly translateService: TranslateService
  ) {}

  transform(
    messageDate: moment.Moment,
    format: 'date-only' | null = null,
    ...args: unknown[]
  ): string {
    if (!messageDate) {
      return '';
    }

    const now = moment();
    const time = formatDate(messageDate.toDate(), 'shortTime', this.locale);
    const date = formatDate(messageDate.toDate(), 'longDate', this.locale);

    const isToday = messageDate.isSame(now, 'day');
    const isYesterday = messageDate.isSame(now.subtract(1, 'day'), 'day');

    switch (format) {
      case 'date-only':
        if (isToday) {
          return this.translateService.instant('message-date-pipe.today');
        }

        if (isYesterday) {
          return this.translateService.instant('message-date-pipe.yesterday');
        }

        return date;
      default:
        if (isToday) {
          return this.translateService.instant('message-date-pipe.today-at', {
            time
          });
        }

        if (isYesterday) {
          return this.translateService.instant(
            'message-date-pipe.yesterday-at',
            {
              time
            }
          );
        }

        return this.translateService.instant('message-date-pipe.past-date-at', {
          date,
          time
        });
    }
  }
}
