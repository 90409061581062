import { createAction, props } from '@ngrx/store';
import { getErrorState } from '../../common/call-state';

export const createNewChannel = createAction(
  '[NewChannel] Create a new channel',
  props<{ memberId: number }>()
);
export const createNewChannelSucceeded = createAction(
  '[NewChannel] Create a new channel succeeded',
  props<{ channelId: string }>()
);
export const createNewChannelFailed = createAction(
  '[NewChannel] Create a new channel failed',
  getErrorState
);
