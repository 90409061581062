import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastMessage'
})
export class LastMessagePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return '';
    }

    if (value.includes('<img class="gif-image"')) {
      return 'GIF';
    }

    return this.stripHtmlTags(value);
  }

  private stripHtmlTags(message: string): string {
    return message.replace(/<.*?>/g, '');
  }
}
