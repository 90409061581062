import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private readonly userAgents = [
    'Modento Connect Android',
    'Modento Connect iOS'
  ];

  constructor(private readonly deviceDetectorService: DeviceDetectorService) {}

  isInMobileApp(): boolean {
    return !!this.userAgents.find((userAgent) =>
      this.deviceDetectorService.userAgent.includes(userAgent)
    );
  }

  isMobileAppIOS(): boolean {
    return this.deviceDetectorService.userAgent.includes('Modento Connect iOS');
  }
}
