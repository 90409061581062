import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { PracticeFacade } from './+state/practice/practice.facade';
import { UserFacade } from './+state/user/user.facade';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { StreamChatFacade } from './stream-chat/+state/stream-chat/stream-chat.facade';
import { CryptoKeyFacade } from './+state/team-chat/crypto-key/crypto-key.facade';
import { StatusFacade } from './+state/status/status.facade';
import { CommunicationService } from './services/communication/communication.service';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RoutesRecognized
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceService } from './services/device/device.service';
import { ErrorPageRedirectReason } from './app.types';
import { CommunicationEventType } from '@localmed/modento-team-chat-types';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  readonly routerLoading$: Subject<boolean> = new Subject<boolean>();

  loading$: Observable<boolean> = combineLatest([
    this.practiceFacade.loading$,
    this.userFacade.loading$,
    this.streamChatFacade.loading$,
    this.cryptoKeyFacade.loading$,
    this.routerLoading$.pipe(startWith(false))
  ]).pipe(map((loadings) => loadings.includes(true)));

  constructor(
    private readonly practiceFacade: PracticeFacade,
    private readonly userFacade: UserFacade,
    private readonly streamChatFacade: StreamChatFacade,
    private readonly cryptoKeyFacade: CryptoKeyFacade,
    private readonly statusFacade: StatusFacade,
    private readonly communicationService: CommunicationService,
    private readonly router: Router,
    private readonly deviceService: DeviceService
  ) { }

  @HostListener('window:focus')
  onFocus(): void {
    this.communicationService.sendEvent({
      type: CommunicationEventType.WINDOW_FOCUS
    });
  }

  @HostListener('window:blur')
  onBlur(): void {
    this.communicationService.sendEvent({
      type: CommunicationEventType.WINDOW_BLUR
    });
  }

  ngOnInit(): void {
    this.statusFacade.initApplication();

    this.communicationService.sendEvent({
      type: CommunicationEventType.CHAT_LOADED
    });

    this.subscribeToNavigationChange();
    this.subscribeToPreviousUrl();
    this.subscribeToNavigationFailed();
    this.checkIfIsMobileApp();
  }

  ngOnDestroy(): void {
    this.statusFacade.destroyApplication();
  }

  private subscribeToNavigationChange(): void {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart || event instanceof NavigationEnd
        ),
        untilDestroyed(this)
      )
      .subscribe((routerEvent: NavigationStart | NavigationEnd) => {
        this.routerLoading$.next(routerEvent instanceof NavigationStart);

        if (routerEvent instanceof NavigationEnd) {
          this.communicationService.sendEvent({
            type: CommunicationEventType.LOCATION_CHANGED,
            data: {
              url: routerEvent.url
            }
          });
        }
      });
  }

  private subscribeToPreviousUrl(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        pairwise(),
        filter(
          ([previous]) =>
            (previous as RoutesRecognized).urlAfterRedirects !== '/error'
        ),
        untilDestroyed(this)
      )
      .subscribe(([previous]) => {
        const segments = this.router.parseUrl(
          (previous as RoutesRecognized).urlAfterRedirects
        ).root.children.primary.segments;
        this.statusFacade.setPreviousUrl(segments.join('/'));
      });
  }

  private subscribeToNavigationFailed() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationError),
        untilDestroyed(this)
      )
      .subscribe((e) => {
        this.router.navigate(['error'], {
          state: {
            reason: ErrorPageRedirectReason.NAVIGATION_ERROR
          }
        });
      });
  }

  private checkIfIsMobileApp(): void {
    this.statusFacade.setIsMobileApp(this.deviceService.isInMobileApp());
  }
}
