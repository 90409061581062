import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationTokenFacade } from '../../+state/authentication-token/authentication-token.facade';
import { concatMap, take } from 'rxjs/operators';

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
  static AUTHORIZATION_HEADER_KEY = 'Authorization';

  constructor(
    private readonly authenticationTokenFacade: AuthenticationTokenFacade
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authenticationTokenFacade.authenticationToken$.pipe(
      take(1),
      concatMap((authenticationToken: string) => {
        const requestWithAuthorizationHeaders = request.clone({
          headers: request.headers.set(
            AuthorizationHeaderInterceptor.AUTHORIZATION_HEADER_KEY,
            `Bearer ${authenticationToken}`
          )
        });

        return next.handle(requestWithAuthorizationHeaders);
      })
    );
  }
}
