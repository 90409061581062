import { VERSION } from '../environments/version';
import { InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';
import { Moment } from 'moment';
import { ChannelTypes } from './stream-chat/stream-chat.types';

export interface AppConfiguration {
  apiUrl: string;
  version: {
    dirty: boolean;
    raw: string;
    hash: string;
    distance: string | null;
    tag: string | null;
    semver: string | null;
    suffix: string;
    semverString: string | null;
    version: string;
  };
  channel: {
    messagesPerPage: number;
  };
  giphy: {
    apiKey: string;
  };
  development: {
    allowRemovingChannels: boolean;
  };
  postMessage: {
    allowedModentoGoUrls: string[];
    allowedDashboardUrls: string[];
  };
}

export const APP_CONFIGURATION = new InjectionToken<AppConfiguration>(
  'APP_CONFIG',
  {
    factory: () => ({
      apiUrl: environment.apiUrl,
      version: VERSION,
      channel: environment.channel,
      giphy: environment.giphy,
      development: environment.development,
      postMessage: {
        allowedModentoGoUrls:
          environment.postMessage.allowedModentoGoUrls ?? [],
        allowedDashboardUrls: environment.postMessage.allowedDashboardUrls ?? []
      }
    })
  }
);

export enum MemberRole {
  ADMIN = 'admin',
  USER = 'user',
  OWNER = 'owner',
  MODERATOR = 'moderator'
}

export enum MessageType {
  REGULAR = 'regular',
  SYSTEM = 'system'
}

export interface Channel {
  id: string;
  type: ChannelTypes;
  name: string;
  createdAt: Moment;
  creatorId: number;
  membership: Membership;
  moderatorIds: number[];
  memberIds: number[];
  memberRoles: {
    [userId: number]: MemberRole;
  };
  isGroup: boolean;
  isOnline: boolean;
  isHidden: boolean;
  isArchived: boolean;
  isTyping: boolean;
  muteStatus: {
    isMuted: boolean;
    expiresAt: Moment | null;
  };
  unreadMessagesCount: number;
  lastMessage: Message | null;
  messages: Message[];
}

export interface Membership {
  memberId: number;
  role: MemberRole;
}

export interface Message {
  id: string;
  type: MessageType;
  createdAt: Moment;
  userId: string;
  text: string;
  silent: boolean;
}

export enum ErrorPageRedirectReason {
  INCORRECT_ROUTER_STATE = 'incorrectRouterState',
  NAVIGATION_ERROR = 'navigationError',
  GET_STREAM_OFFLINE = 'getStreamOffline',
  HTTP_REQUEST_ERROR = 'httpRequestError',
  NO_AUTH_TOKEN = 'noAuthToken',
  LOAD_USER_ERROR = 'loadUserError',
  LOAD_CHANNELS_ERROR = 'loadChannelsError',
  LOAD_CRYPTO_KEY_ERROR = 'loadCryptoKeyError',
  NO_PRACTICE_GUID = 'noPracticeGuid',
  LOAD_PRACTICE_ERROR = 'loadPracticeError',
  INIT_STREAM_CHAT_ERROR = 'initStreamChatError',
  LOAD_MEMBERS_ERROR = 'loadMembersError'
}
