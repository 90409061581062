import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY, reducer } from './stream-chat.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StreamChatEffects } from './stream-chat.effects';
import { StreamChatChannelEffects } from './stream-chat-channel.effects';
import { StreamChatCreateChannelEffects } from './stream-chat-create-channel.effects';
import { StreamChatChannelsEffects } from './stream-chat-channels.effects';
import { StreamChatMembersEffects } from './stream-chat-members.effects';
import { StreamChatChannelActionsEffects } from './stream-chat-channel-actions.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([
      StreamChatEffects,
      StreamChatChannelEffects,
      StreamChatCreateChannelEffects,
      StreamChatChannelsEffects,
      StreamChatMembersEffects,
      StreamChatChannelActionsEffects
    ])
  ]
})
export class StreamChatStateModule {}
