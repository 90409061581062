import { createAction, props } from '@ngrx/store';
import { User } from '../../data-access/user';
import { getErrorState } from '../../common/call-state';

export const loadUser = createAction('[User] Load user');

export const loadUserSucceeded = createAction(
  '[User] Load user succeeded',
  props<{ user: User }>()
);

export const loadUserFailed = createAction(
  '[User] Load user failed',
  getErrorState
);
