import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { PracticeFacade } from '../+state/practice/practice.facade';
import { AuthenticationTokenFacade } from '../+state/authentication-token/authentication-token.facade';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { StatusFacade } from '../+state/status/status.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CommunicationService } from '../services/communication/communication.service';
import { ErrorPageRedirectReason } from '../app.types';
import { IncomingPostMessageEventType } from '@localmed/modento-team-chat-types';

@UntilDestroy()
@Component({
  selector: 'app-error',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorViewComponent implements OnInit {
  practiceGuid$: Observable<string> = this.practiceFacade.practiceGuid$;
  authenticationToken$: Observable<string> =
    this.authenticationTokenFacade.authenticationToken$;
  previousUrl$: Observable<string> = this.statusFacade.previousUrl$;

  private readonly forceNavigateToPreviousUrl$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly refreshClicked$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private redirectReason: ErrorPageRedirectReason = null;

  constructor(
    private readonly practiceFacade: PracticeFacade,
    private readonly authenticationTokenFacade: AuthenticationTokenFacade,
    private readonly statusFacade: StatusFacade,
    private readonly router: Router,
    private readonly matDialog: MatDialog,
    private readonly communicationService: CommunicationService
  ) {
    this.redirectReason =
      this.router.getCurrentNavigation().extras.state?.reason;
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(event): void {
    if (
      !this.communicationService.checkIfPostMessageComesFromModentoGo(
        event.origin
      )
    ) {
      return;
    }

    switch (event.data.type) {
      case IncomingPostMessageEventType.RELOAD_IF_ERROR:
        // when Modento Go app has been focused/resumed
        this.forceNavigateToPreviousUrl$.next(true);
        break;
    }
  }

  ngOnInit(): void {
    this.closeAllModals();
    this.subscribeToRefreshRequest();
    this.subscribeToOnlineStatus();
  }

  refreshClicked(): void {
    this.refreshClicked$.next(true);
  }

  private subscribeToRefreshRequest(): void {
    merge(
      this.statusFacade.connectionHasBeenRestored$,
      this.forceNavigateToPreviousUrl$,
      this.refreshClicked$
    )
      .pipe(
        filter((reload) => !!reload),
        withLatestFrom(
          this.previousUrl$,
          this.practiceGuid$,
          this.authenticationToken$
        ),
        untilDestroyed(this)
      )
      .subscribe(
        ([
          connectionRestored,
          previousUrl,
          practiceGuid,
          authenticationToken,
        ]) => {
          if (previousUrl) {
            this.router.navigate([previousUrl], {
              queryParams: {
                token: authenticationToken,
              },
            });
          } else if (practiceGuid && authenticationToken) {
            this.router.navigate([practiceGuid], {
              queryParams: {
                token: authenticationToken,
              },
            });
          }
        }
      );
  }

  private subscribeToOnlineStatus(): void {
    this.statusFacade.online$
      .pipe(
        take(1),
        filter((online) => !!online)
      )
      .subscribe(() => {
        if (
          this.redirectReason === ErrorPageRedirectReason.GET_STREAM_OFFLINE
        ) {
          this.forceNavigateToPreviousUrl$.next(true);
        }
      });
  }

  private closeAllModals(): void {
    this.matDialog.closeAll();
  }
}
