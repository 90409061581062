import { Action, createReducer, on } from '@ngrx/store';
import { setAuthenticationToken } from './authentication-token.actions';

export const FEATURE_KEY = 'authentication-token';

export interface State {
  authenticationToken: string | null;
}

export const initialState: State = {
  authenticationToken: null
};

const authenticationTokenReducer = createReducer(
  initialState,
  on(setAuthenticationToken, (state, { authenticationToken }) => ({
    ...state,
    authenticationToken
  }))
);

export const reducer = (state: State, action: Action): State => {
  return authenticationTokenReducer(state, action);
};
