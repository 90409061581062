import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConverterService } from '../../converter.service';
import {
  getMemberFailed,
  getMembers,
  getMembersSucceeded
} from '../../../+state/team-chat/member/member.actions';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { TeamChatMember } from '../../../data-access/team-chat/team-chat.types';
import { Observable, of, zip } from 'rxjs';
import { QueryUsersResponse } from '../../stream-chat.types';
import { UserResponse } from 'stream-chat';
import { Injectable } from '@angular/core';
import { StreamChatService } from '../../stream-chat.service';
import { TeamChatService } from '../../../data-access/team-chat/team-chat.service';
import * as Sentry from '@sentry/browser';
import { StatusFacade } from '../../../+state/status/status.facade';
import { StreamChatFacade } from './stream-chat.facade';

@Injectable()
export class StreamChatMembersEffects {
  getMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMembers),
      switchMap(() =>
        this.teamChatService.getTeamMembers().pipe(
          switchMap((teamChatMembers: TeamChatMember[]) => {
            const usersPerPage = 30;

            const teamChatMembersLength = teamChatMembers.length;

            const requests: Observable<QueryUsersResponse>[] = [];

            if (teamChatMembersLength > 0) {
              for (let i = 0; i < teamChatMembersLength; i += usersPerPage) {
                const ids = teamChatMembers
                  .slice(i, i + usersPerPage)
                  .map((t) => t.id.toString());

                requests.push(
                  this.streamChatService.fetchUsers(ids).pipe(
                    catchError((error) => {
                      Sentry.captureEvent({
                        message: 'queryUsers failed extras',
                        extra: {
                          error,
                          connectionId: this.streamChatService.client.wsConnection
                            .connectionID,
                          // eslint-disable-next-line no-underscore-dangle
                          hasConnectionId: this.streamChatService.client._hasConnectionID(),
                          ids,
                          page: i / usersPerPage
                        }
                      });

                      throw error;
                    })
                  )
                );
              }
            } else {
              requests.push(of(([] as unknown) as QueryUsersResponse));
            }

            return zip(...requests).pipe(
              map((usersBundles) => {
                const users: UserResponse[] = [].concat(
                  ...usersBundles.map((bundle) => bundle.users)
                );

                return teamChatMembers.map((member) =>
                  this.converterService.convertTeamMemberAndStreamChatUserToMember(
                    member,
                    users.find((user) => +user.id === member.id)
                  )
                );
              }),
              map((members) =>
                getMembersSucceeded({
                  members
                })
              )
            );
          }),
          catchError((error) =>
            of(null).pipe(
              withLatestFrom(
                this.statusFacade.online$,
                this.streamChatFacade.loaded$
              ),
              tap(([e, online, loaded]) => {
                Sentry.captureEvent({
                  message: 'queryUsers failed',
                  extra: {
                    error,
                    connectionId: this.streamChatService.client.wsConnection
                      .connectionID,
                    // eslint-disable-next-line no-underscore-dangle
                    hasConnectionId: this.streamChatService.client._hasConnectionID(),
                    online,
                    loaded
                  }
                });
              }),
              switchMap(() => of(getMemberFailed(error)))
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly converterService: ConverterService,
    private readonly streamChatService: StreamChatService,
    private readonly teamChatService: TeamChatService,
    private readonly statusFacade: StatusFacade,
    private readonly streamChatFacade: StreamChatFacade
  ) { }
}
