import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserFacade } from '../../user/user.facade';
import {
  setOnlineStatus,
  setOnlineStatusFailed,
  setOnlineStatusSucceeded
} from './member.actions';
import { catchError, filter, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class MemberEffects {
  setOnlineStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setOnlineStatus),
      withLatestFrom(this.userFacade.user$),
      filter(([action, user]) => +action.memberId !== user.team_member.id),
      map(([action]) =>
        setOnlineStatusSucceeded({
          memberId: +action.memberId,
          isOnline: action.isOnline
        })
      ),
      catchError((error) => of(setOnlineStatusFailed(error)))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userFacade: UserFacade
  ) {}
}
