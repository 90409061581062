import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorRoutingModule } from './error-routing.module';
import { ErrorViewComponent } from './error-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { OhNoComponent } from './oh-no/oh-no.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ErrorViewComponent, OhNoComponent],
  imports: [
    CommonModule,
    ErrorRoutingModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class ErrorModule {}
