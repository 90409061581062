import { Injectable } from '@angular/core';
import { TeamChatService } from '../../../data-access/team-chat/team-chat.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getCryptoKey,
  getCryptoKeyFailed,
  getCryptoKeySucceeded
} from './crypto-key.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CryptoKeyEffects {
  getCryptoKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoKey),
      switchMap(() =>
        this.teamChatService.getCryptoKey().pipe(
          map((cryptoKey) => getCryptoKeySucceeded({ cryptoKey })),
          catchError((error) => of(getCryptoKeyFailed(error)))
        )
      )
    )
  );

  constructor(
    private readonly teamChatService: TeamChatService,
    private readonly actions$: Actions
  ) {}
}
