import { Action, createReducer, on } from '@ngrx/store';
import { CallState, LoadingState } from '../../../common/call-state';
import {
  getCryptoKey,
  getCryptoKeyFailed,
  getCryptoKeySucceeded
} from './crypto-key.actions';

export const FEATURE_KEY = 'crypto-key';

export interface State {
  callState: CallState;
  cryptoKey: string;
}

export const initialState: State = {
  callState: LoadingState.INIT,
  cryptoKey: null
};

const cryptoKeyReducer = createReducer(
  initialState,
  on(getCryptoKey, (state) => ({
    ...state,
    callState: LoadingState.LOADING
  })),
  on(getCryptoKeySucceeded, (state, { cryptoKey }) => ({
    ...state,
    callState: LoadingState.LOADED,
    cryptoKey
  })),
  on(getCryptoKeyFailed, (state, error) => ({
    ...state,
    callState: error
  }))
);

export const reducer = (state: State, action: Action): State => {
  return cryptoKeyReducer(state, action);
};
