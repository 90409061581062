import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State } from './stream-chat.reducer';
import { LoadingState } from '../../../common/call-state';

const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADING
);

export const selectLoaded = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADED
);

export const selectError = createSelector(selectState, (state: State) => {
  return typeof state.callState !== 'object' ? null : state.callState;
});
