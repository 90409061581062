import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { StreamChatFacade } from '../stream-chat/+state/stream-chat/stream-chat.facade';
import { ChannelsFacade } from '../+state/channels/channels.facade';
import { ErrorPageRedirectReason } from '../app.types';

@Injectable({
  providedIn: 'root'
})
export class ChannelsGuard  {
  constructor(
    private readonly streamChatFacade: StreamChatFacade,
    private readonly channelsFacade: ChannelsFacade,
    private readonly router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.streamChatFacade.loaded$.pipe(
      filter((loaded) => !!loaded), // stream chat has to be loaded to get channels
      take(1),
      tap(() => this.channelsFacade.getChannels()),
      switchMap(() =>
        combineLatest([
          this.channelsFacade.loaded$.pipe(startWith(false)),
          this.channelsFacade.error$.pipe(startWith(false))
        ]).pipe(
          filter(([loaded, error]) => !!loaded || !!error),
          take(1),
          map(([loaded]) => {
            if (loaded) {
              return true;
            } else {
              this.router.navigate(['error'], {
                state: { reason: ErrorPageRedirectReason.LOAD_CHANNELS_ERROR }
              });
              return false;
            }
          })
        )
      )
    );
  }
}
